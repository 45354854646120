import React, { memo, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { tableCustomStyles } from "../../Components/Common/Utility";
import DataTable from "react-data-table-component";


const FlagTableModal = (props) => {
    const { openFlagTableModal, setOpenFlagTableModal } = props;
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const [loginPinID, setLoginPinID] = useState(1);

    useEffect(() => {
        if (localStoreData) {
            setLoginPinID(localStoreData?.PINID)

        }
    }, [localStoreData]);


    const handleClose = () => {
        setOpenFlagTableModal(false);
    };

    const columns = [
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
            cell: row => <>
                {
                    <span
                        onClick={() => { }}
                        className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                        <i className="fa fa-edit"></i>
                    </span>
                }
            </>
        },
        {
            name: 'RMS Incident #',
            selector: (row) => <>{row?.LastName ? row?.LastName.substring(0, 10) : ''}{row?.LastName?.length > 20 ? '  . . .' : null} </>,
            sortable: true
        },
        {
            name: 'Reported DT/TM',
            selector: (row) => <>{row?.FirstName ? row?.FirstName.substring(0, 10) : ''}{row?.FirstName?.length > 20 ? '  . . .' : null} </>,
            sortable: true
        },
        {
            name: 'CFS Code',
            selector: (row) => <>{row?.MiddleName ? row?.MiddleName.substring(0, 10) : ''}{row?.MiddleName?.length > 20 ? '  . . .' : null} </>,
            sortable: true
        },
        {
            name: 'CFS Description',
            selector: (row) => <>{row?.Age ? row?.Age.substring(0, 10) : ''}{row?.Age?.length > 20 ? '  . . .' : null} </>,
            sortable: true
        },
        {
            name: 'Priority',
            selector: (row) => <>{row?.RaceID ? row?.RaceID : ''}</>,
            sortable: true
        },
    ]


    return (
        <>
            {openFlagTableModal ? (
                <>
                    <dialog
                        className="modal fade"
                        style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
                        id="FlagTableModal"
                        tabIndex="-1"
                        aria-hidden="true"
                        data-backdrop="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content modal-content-cad">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-22 p-0 pb-2">
                                            <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                                <p
                                                    className="p-0 m-0 font-weight-medium"
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: 500,
                                                        letterSpacing: 0.5,
                                                    }}
                                                >
                                                    24 Hr History
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Form Section */}
                                    <div className="m-1">
                                        <fieldset style={{ border: "1px solid gray" }}>
                                            <div className="tab-form-container">
                                                <div className="table-responsive CAD-table" style={{ position: "sticky" }}>
                                                    <DataTable
                                                        dense
                                                        columns={columns}
                                                        data={[]}
                                                        customStyles={tableCustomStyles}
                                                        pagination
                                                        responsive
                                                        striped
                                                        highlightOnHover
                                                        noDataComponent={true ? "There are no data to display" : 'There are no data to display'}
                                                        fixedHeader
                                                        selectableRowsHighlight
                                                        fixedHeaderScrollHeight="190px"
                                                        persistTableHead={true}

                                                    />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    {/* Buttons Section */}
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <div className="py-0 px-2 d-flex justify-align-content-between align-items-center">
                                                <div className="tab-form-label text-nowrap"> Total Records : 0</div>
                                                <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                    <button
                                                        type="button"
                                                        data-dismiss="modal"
                                                        className="cancel-button"
                                                        onClick={() => handleClose()}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dialog >
                </>
            ) : (
                <> </>
            )
            }
        </>
    );
};

export default memo(FlagTableModal);
