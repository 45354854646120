import React, { useState } from 'react';

const Tooltip = ({ text, maxLength }) => {
    const [isHovered, setIsHovered] = useState(false);
    const isTruncated = text.length > maxLength;
    const displayText = isTruncated ? text.substring(0, maxLength) + '...' : text;

    const tooltipBoxStyles = {
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '300px', // Fixed width for the tooltip
        backgroundColor: '#333',
        color: '#fff',
        padding: '8px',
        borderRadius: '4px',
        textAlign: 'left',
        whiteSpace: 'normal', // Allow text to wrap
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
    };

    const tooltipArrowStyles = {
        content: '""',
        position: 'absolute',
        top: '-5px',
        left: '50%',
        transform: 'translateX(-50%)',
        borderWidth: '5px',
        borderStyle: 'solid',
        borderColor: 'transparent transparent #333 transparent',
    };

    return (
        <div
            style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <span style={{ fontSize: '14px' }}>{displayText}</span>
            {isHovered && isTruncated && (
                <div style={tooltipBoxStyles}>
                    <div style={tooltipArrowStyles} />
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
