import React, { memo, useState, useRef, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { colourStyles, getShowingDateText, tableCustomStyles } from "../../Components/Common/Utility";
import BoloSearchModal from "../BoloSearchModal";
import CallTakerModal from "../CallTakerModal";
import { customStylesWithFixedHeight } from "../Utility/CustomStylesForReact";
import MasterTableListServices from "../../CADServices/APIs/masterTableList";
import { useQuery } from "react-query";
import { IncidentContext } from "../../CADContext/Incident";
import useObjState from "../../CADHook/useObjState";
import { useSelector } from "react-redux";
import { compareStrings, dropDownDataModel, isEmpty } from "../../CADUtils/functions/common";
import BoloServices from "../../CADServices/APIs/bolo";
import CloseBoloModal from "../CloseBoloModal";
import { AddDelete_Img } from "../../Components/hooks/Api";
import ViewImageModal from "../ViewImageModal/ViewImageModal";
import Tooltip from "../Common/Tooltip";
import ViewSingleImageModal from "../ViewSingleImageModal/ViewSingleImageModal";
import ModalConfirm from "../Common/ModalConfirm";


const BoloModal = (props) => {
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const { openBoloModal, setOpenBoloModal } = props;
    const [selectedImages, setSelectedImages] = useState([])
    const [viewImg, setViewImg] = useState([])
    const [imageModalStatus, setImageModalStatus] = useState(false);
    const [isOpenViewSingleImageModal, setIsOpenViewSingleImageModal] = useState(false);
    const { incidentData, resourceRefetch, incidentRefetch } = useContext(IncidentContext);
    const [boloDisposition, setBoloDisposition] = useState([]);
    const [typeOFBOLO, setTypeOFBOLO] = useState([]);
    const [prioritiesDropDown, setPrioritiesDropDown] = useState([]);
    const [zoneDropDown, setZoneDropDown] = useState([])
    const [showPage, setShowPage] = useState("home")
    const [openBoloSearchModal, setOpenBoloSearchModal] = useState(false);
    const [openCallTakerModal, setCallTakerModal] = useState(false);
    const [openCloseBolo, setOpenCloseBolo] = useState(false);
    const [loginPinID, setLoginPinID] = useState('');
    const [priorityFilter, setPriorityFilter] = useState('');
    const [typeOfBoloFilter, setTypeOfBoloFilter] = useState('');
    const [searchBoloID, setSearchBoloID] = useState('');
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [boloList, setBoloList] = useState([]);
    const [incNo, setIncNo] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [viewSingleImage, setViewSingleImage] = useState("")
    const [clickedRow, setClickedRow] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmParams, setConfirmParams] = useState(null);
    const [isDataUpdated, setIsDataUpdated] = useState(false);
    const fileInputRef = useRef(null);
    const [
        boloState,
        setBoloState,
        handleBoloState,
        clearBoloState,
    ] = useObjState({
        BoloID: "",
        TypeOfBolo: "",
        IncidentID: "",
        Priority: "",
        Zone: "",
        Message: "",
        DeleteAfter: "",
        BOLODisposition: ""
    });
    const [
        initialBoloState,
        setInitialBoloState,
        handleInitialBoloState,
        clearInitialBoloState,
    ] = useObjState({
        BoloID: "",
        TypeOfBolo: "",
        IncidentID: "",
        Priority: "",
        Zone: "",
        Message: "",
        DeleteAfter: "",
        BOLODisposition: ""
    });
    const [
        errorState,
        _setErrorState,
        handleErrorState,
        clearErrorState,
    ] = useObjState({
        Message: false,
    });

    useEffect(() => {
        if (incNo) {
            setBoloState(prevState => ({
                ...prevState,
                IncidentID: incNo
            }));

        }
    }, [incNo])

    useEffect(() => {
        if (localStoreData) {
            setLoginPinID(localStoreData?.PINID);
            setLoginAgencyID(localStoreData?.AgencyID);
        }
    }, [localStoreData]);

    const getImages = async (boloID) => {
        if (boloID) {
            const payload = {
                "IsActive": true,
                "BoloId": boloID
            }
            const response = await BoloServices.getBoloDoc(payload);
            if (response?.data?.success) {
                const parsedData = JSON.parse(response?.data?.data);
                const viewImgData = parsedData?.Table;
                setSelectedImages(viewImgData)
            } else {
                setSelectedImages([]);
            }
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const boloID = params.get("boloID");
        setSearchBoloID(boloID);
        getImages(boloID);

    }, [openBoloSearchModal]);

    const getBoloDispositionKey = `/CAD/Monitor/MasterBoloDispositionGet/${parseInt(1)}`;
    const { data: getBoloDispositionData, isSuccess: isFetchBoloDisposition } = useQuery(
        [getBoloDispositionKey, {
            "IsActive": 1,
        },],
        MasterTableListServices.getBoloDisposition,
        {
            refetchOnWindowFocus: false,
            enabled: openBoloModal,
        }
    )

    const getBoloTypeKey = `/CAD/MasterBoloType/GetBoloType/${parseInt(1)}`;
    const { data: getBoloTypeData, isSuccess: isFetchBoloType } = useQuery(
        [getBoloTypeKey, {
            "Action": "GET",
            "IsActive": 1,
            "AgencyID": loginAgencyID

        },],
        MasterTableListServices.getBoloType,
        {
            refetchOnWindowFocus: false,
            enabled: openBoloModal,
        }
    );
    const getPriorityKey = `/CAD/MasterPriority/GetMasterPriority`;
    const { data: prioritiesData, isSuccess: isFetchPrioritiesData } = useQuery(
        [getPriorityKey, {
            IsActive: 1,
            AgencyID: loginAgencyID,
        }],
        MasterTableListServices.getMasterPriority,
        {
            refetchOnWindowFocus: false,
            retry: 0,
            enabled: !!loginAgencyID,
        }
    );

    useEffect(() => {
        if (isFetchPrioritiesData && prioritiesData) {
            const data = JSON.parse(prioritiesData?.data?.data);
            setPrioritiesDropDown(data?.Table);
        }
    }, [isFetchPrioritiesData, prioritiesData]);

    const getBoloKey = `/CAD/Bolo/GetBolo`;
    const { data: getBoloData, isSuccess: isFetchBoloData, refetch, isError: isNoData } = useQuery(
        [getBoloKey, {
            "AgencyID": loginAgencyID,
            "BoloTypeID": typeOfBoloFilter,
            "PriorityID": priorityFilter

        },],
        BoloServices.getBolo,
        {
            refetchOnWindowFocus: false,
            enabled: openBoloModal && !!loginAgencyID,
            retry: 0,
        }
    );
    const getByIdBoloKey = `/CAD/Bolo/GetBolo/${searchBoloID}`;
    const { data: getByIdBoloData, isSuccess: isFetchByIdBoloData } = useQuery(
        [getByIdBoloKey, {
            "BoloID": searchBoloID,
            "AgencyID": loginAgencyID

        },],
        BoloServices.getBolo,
        {
            refetchOnWindowFocus: false,
            enabled: openBoloModal && !!loginAgencyID && !!searchBoloID && !openBoloSearchModal,
        }
    );

    useEffect(() => {
        if (getByIdBoloData && isFetchByIdBoloData) {
            const data = JSON.parse(getByIdBoloData?.data?.data || [])?.Table?.[0];
            setBoloState({
                BoloID: data?.BoloID,
                TypeOfBolo: data?.BoloTypeID,
                IncidentID: data?.IncidentID,
                Priority: data?.PriorityID,
                Zone: data?.ZoneID,
                Message: data?.Message,
                DeleteAfter: data?.DeleteAfter,
                BOLODisposition: data?.BoloDispositionID,
            })
        }

    }, [isFetchByIdBoloData, getByIdBoloData])

    useEffect(() => {
        if (isFetchBoloData && getBoloData) {
            const data = JSON.parse(getBoloData?.data?.data || []);
            setBoloList(data?.Table);
        } else {
            setBoloList([])
        }
    }, [isFetchBoloData, getBoloData])

    const getZoneKey = `/CAD/GeoPetrolZone/GetData_Zone`;
    const { data: getZoneData, isSuccess: isFetchGeoZoneData } = useQuery(
        [getZoneKey, { IsActive: 1 },],
        MasterTableListServices.getDataZone,
        {
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        if (isFetchGeoZoneData && getZoneData) {
            const data = JSON.parse(getZoneData?.data?.data || []);
            setZoneDropDown(dropDownDataModel(data?.Table, "ZoneID", "ZoneDescription"));
        }
    }, [isFetchGeoZoneData, getZoneData]);

    useEffect(() => {
        if (getBoloTypeData && isFetchBoloType) {
            const data = JSON.parse(getBoloTypeData?.data?.data);
            setTypeOFBOLO(data?.Table)
        } else {
            setTypeOFBOLO([])
        }
    }, [getBoloTypeData, isFetchBoloType])

    useEffect(() => {
        if (getBoloDispositionData && isFetchBoloDisposition) {
            const data = JSON.parse(getBoloDispositionData?.data?.data);
            setBoloDisposition(data?.Table)
        } else {
            setBoloDisposition([])
        }
    }, [getBoloDispositionData, isFetchBoloDisposition])

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const filteredImages = files.filter((file) =>
            file.type === 'image/png' || file.type === 'image/jpeg'
        );
        setSelectedImages((prevImages) => [...prevImages, ...filteredImages]);
        setIsDataUpdated(true)
    };

    const removeImage = async (index, image) => {
        setSelectedImages((prevImages) => {
            const updatedImages = prevImages.filter((_, i) => i !== index);
            if (updatedImages.length === 0 && fileInputRef.current) {
                fileInputRef.current.value = null;
            }
            return updatedImages;
        });
        if (image?.DocumentID) {
            const payload = {
                DeletedByUserFK: loginPinID || "",
                DocumentID: image?.DocumentID,
                IsActive: false
            }
            await BoloServices.deleteBoloDoc(payload);
        }
    };

    function handleCancel() {
        const baseUrl = window.location.origin + window.location.pathname;
        window.history.pushState(null, '', baseUrl);
        handelClear();
        setOpenBoloModal(false)
    }

    function handelClear() {
        const baseUrl = window.location.origin + window.location.pathname;
        window.history.pushState(null, '', baseUrl);
        clearBoloState();
        clearErrorState();
        setSelectedImages([])
        setPriorityFilter("");
        setTypeOfBoloFilter("");
        setClickedRow("");
        setIsDataUpdated(false)
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }

    async function handelSetEditData(data) {
        setBoloState({
            BoloID: data?.BoloID || "",
            TypeOfBolo: data?.BoloTypeID || "",
            IncidentID: data?.IncidentID || "",
            Priority: data?.PriorityID || "",
            Zone: data?.ZoneID || "",
            Message: data?.Message || "",
            DeleteAfter: data?.DeleteAfter || "",
            BOLODisposition: data?.BoloDispositionID || "",
        })
        setInitialBoloState({
            BoloID: data?.BoloID || "",
            TypeOfBolo: data?.BoloTypeID || "",
            IncidentID: data?.IncidentID || "",
            Priority: data?.PriorityID || "",
            Zone: data?.ZoneID || "",
            Message: data?.Message || "",
            DeleteAfter: data?.DeleteAfter || "",
            BOLODisposition: data?.BoloDispositionID || "",
        })
        getImages(data?.BoloID)
    }
    useEffect(() => {
        if (initialBoloState) {
            setIsDataUpdated(JSON.stringify(boloState) !== JSON.stringify(initialBoloState));
        }
    }, [boloState, initialBoloState]);

    async function viewBoloImage(data) {
        setImageModalStatus(true);
        if (data?.BoloID) {
            const payload = {
                "IsActive": true,
                "BoloId": data?.BoloID
            }
            const response = await BoloServices.getBoloDoc(payload);
            if (response?.data?.success) {
                const parsedData = JSON.parse(response?.data?.data);
                const viewImgData = parsedData?.Table;
                setViewImg(viewImgData)
            } else {
                setViewImg([])
            }
        }
    }

    const validateForm = () => {
        let isError = false;
        const keys = Object.keys(errorState);
        keys.map((field) => {
            if (
                field === "Message" &&
                isEmpty(boloState[field])) {
                handleErrorState(field, true);
                isError = true;
            } else {
                handleErrorState(field, false);
            }
        });
        return !isError;
    };

    async function handleSave() {
        if (!validateForm()) return
        setIsLoading(true)
        const isUpdate = !!boloState?.BoloID
        const data = {
            BoloID: isUpdate ? boloState?.BoloID : undefined,
            IncidentId: boloState?.IncidentID,
            BoloTypeId: boloState?.TypeOfBolo,
            PriorityId: boloState?.Priority,
            ZoneId: boloState?.Zone,
            Message: boloState?.Message,
            DeleteAfterDays: boloState?.DeleteAfter,
            BoloDispositionId: boloState?.BOLODisposition,
            IsArchived: 0,
            AgencyID: loginAgencyID,
            CreatedByUserFK: isUpdate ? undefined : loginPinID,
            ModifiedByUserFK: isUpdate ? loginPinID : undefined
        }
        const response = await BoloServices.insertBolo(data);
        if (response?.status === 200) {
            const data = JSON.parse(response?.data?.data);
            if (selectedImages.length > 0) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                Add_Documents(!!boloState?.BoloID ? boloState?.BoloID : data?.Table?.[0]?.BoloTypeID);
            }
            refetch();
            incidentRefetch();
            resourceRefetch();
        }
        handelClear()
        setIsLoading(false)
    }

    const Add_Documents = async (BoloTypeID) => {
        if (!selectedImages || selectedImages.length === 0) {
            console.warn("No images selected. API call skipped.");
            return;
        }

        const formdata = new FormData();
        const EncFormdata = new FormData();
        let hasValidFiles = false;

        for (let i = 0; i < selectedImages.length; i++) {
            if (selectedImages[i] instanceof File) {
                formdata.append("File", selectedImages[i]);
                EncFormdata.append("File", selectedImages[i]);
                hasValidFiles = true;
            }
        }

        // If no valid files were found, skip the API call
        if (!hasValidFiles) {
            console.warn("No valid files found in selectedImages. API call skipped.");
            return;
        }

        const val = {
            BoloId: BoloTypeID,
            IsActive: true,
            AgencyID: loginAgencyID,
            CreatedByUserFK: loginPinID,
        };

        const valuesArray = [`${JSON.stringify(val)}`];
        const formattedValues = JSON.stringify(valuesArray);
        formdata.append("Data", formattedValues);
        AddDelete_Img('/CAD/Bolo/InsertBoloDocuments', formdata, EncFormdata)
            .then((res) => {
                if (res.success) {
                    // const parsedData = JSON.parse(res.data);
                    // const message = parsedData.Table[0].Message;
                    // toastifySuccess(message);
                    refetch();
                    clearBoloState();
                    clearErrorState();
                    incidentRefetch();
                    resourceRefetch();
                } else {
                    console.warn("Something went wrong");
                }
            })
            .catch(err => console.warn("Error:", err));
    };

    const contactList = [];

    const conditionalRowStyles = [
        {
            when: row => row === clickedRow,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
            },
        }
    ];

    const columns2 = [

        {
            name: "Column Name",
            selector: (row) => (row.MiddleName ? row.MiddleName : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.MiddleName, rowB.MiddleName),
        },
        {
            name: "Old Value",
            selector: (row) => (row.FirstName ? row.FirstName : ""),

            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.FirstName, rowB.FirstName),
        },
        {
            name: "New Value",
            selector: (row) => (row.PhoneType ? row.PhoneType : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.PhoneType, rowB.PhoneType),
        },
        {
            name: "Change Date",
            selector: (row) =>
                row.PhoneNo || "",
            sortable: true,
        },
    ];

    const columns = [
        // {
        //     name: "View",
        //     selector: (row) => {
        //         if (row?.IsDoucumentsAvailable === 1) {
        //             return <div data-toggle="modal" data-target="#ViewImageModal" onClick={() => { viewBoloImage(row) }} style={{ cursor: "pointer" }}>
        //                 <i className="fa fa-eye"></i>
        //             </div>;
        //         }
        //         return "-";
        //     },
        //     width: "68px",
        // },
        {
            name: "Priority",
            selector: (row) => (row.Priority ? row.Priority : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.Priority, rowB.Priority),
            width: "120px",
        },
        {
            name: "CAD Event #",
            selector: (row) => (row.IncidentNumber ? row.IncidentNumber : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.IncidentNumber, rowB.IncidentNumber),
            width: "120px",
        },
        {
            name: "Date/Time",
            selector: (row) => (row.CreatedDtTm ? getShowingDateText(row.CreatedDtTm) : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.CreatedDtTm, rowB.CreatedDtTm),
            width: "160px",
        },
        {
            name: "Elapsed Days",
            selector: (row) => (row.ElapsedDays ? row.ElapsedDays : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.ElapsedDays, rowB.ElapsedDays),
            width: "120px",
        },
        {
            name: "BOLO Type",
            selector: (row) => (row.BoloType ? row.BoloType : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.BoloType, rowB.BoloType),
            width: "140px",
        },
        {
            name: "Message",
            selector: (row) => (row.Message ? row.Message : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.Message, rowB.Message),
            width: "300px",
            cell: (row) => (
                <Tooltip text={row?.Message || ''} maxLength={35} />
            ),
        },
        {
            name: "Dispatcher",
            selector: (row) => (row.FullName ? row.FullName : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.FullName, rowB.FullName),
            width: "170px",
        },
        {
            name: "Delete After",
            selector: (row) => (row.DeleteAfter ? row.DeleteAfter : ""),
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.DeleteAfter, rowB.DeleteAfter),
            width: "120px",
        },
    ];
    return (
        <>
            {openBoloModal ? (
                <dialog
                    className="modal fade modal-cad"
                    style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200", overflow: "hidden", }}
                    id="BoloModal"
                    tabIndex="-1"
                    aria-hidden="true"
                    data-backdrop="false"
                >
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content modal-content-cad" style={{
                            maxHeight: "calc(100vh - 100px)",
                            overflowY: "auto",
                        }}>
                            <div className="modal-body">
                                {/* Modal Header */}
                                <div className="row pb-2">
                                    <div className="col-12 ">
                                        <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                            <p
                                                className="p-0 m-0 font-weight-medium"
                                                style={{
                                                    fontSize: 18,
                                                    fontWeight: 500,
                                                    letterSpacing: 0.5,
                                                }}
                                            >
                                                BOLO
                                            </p>
                                            <div class="notification_dropdown">
                                                <a id="dLabel" role="button" data-toggle="dropdown" data-target="#" href="/page.html">
                                                    <i class="fa fa-bell"></i>
                                                </a>

                                                {/* <ul class="dropdown-menu notifications" role="menu" aria-labelledby="dLabel">
                                                    <div class="notifications-wrapper">
                                                        <div class="notification-item">
                                                            <span class="item-title">Evaluation Deadline 1 · day ago</span>
                                                        </div>
                                                        <div class="notification-item">
                                                            <span class="item-title">Evaluation Deadline 1 · day ago</span>
                                                        </div>
                                                        <div class="notification-item">
                                                            <span class="item-title">Evaluation Deadline 1 · day ago</span>
                                                        </div>
                                                        <div class="notification-item">
                                                            <span class="item-title">Evaluation Deadline 1 · day ago</span>
                                                        </div>
                                                        <div class="notification-item border-0">
                                                            <span class="item-title">Evaluation Deadline 1 · day ago</span>
                                                        </div>
                                                    </div>
                                                </ul> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-1">
                                    <div className="col-12 name-tab m-0">
                                        <ul className='nav nav-tabs mx-1'>
                                            <span
                                                className={`nav-item ${showPage === 'home' ? 'active' : ''}`}
                                                style={{ color: showPage === 'home' ? 'Red' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { setShowPage('home') }}

                                            >
                                                <i className="fa fa-home" style={{ fontSize: '20px' }}></i>
                                            </span>
                                            <span
                                                className={`nav-item border-0 ${showPage === 'auditLog' ? 'active' : ''}`}
                                                style={{ color: showPage === 'auditLog' ? 'Red' : '#000' }}
                                                aria-current="page"
                                                onClick={() => { }}
                                            // onClick={() => { setShowPage('auditLog') }}

                                            >
                                                Change Log
                                            </span>
                                        </ul>
                                    </div>
                                </div>
                                {showPage === 'home' &&
                                    <>
                                        {/* Form Area */}
                                        <div className="m-1">
                                            <fieldset style={{ border: "1px solid gray" }}>
                                                <div className="tab-form-container">
                                                    <div className="tab-form-row">
                                                        <div className="col-1 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Type of BOLO</label>
                                                        </div>
                                                        <div className="d-flex col-11">
                                                            <Select
                                                                name="TypeOfBolo"
                                                                styles={customStylesWithFixedHeight}
                                                                isClearable
                                                                options={typeOFBOLO}
                                                                value={boloState?.TypeOfBolo ? typeOFBOLO?.find((i) => i?.BoloTypeID == boloState?.TypeOfBolo) : ""}
                                                                getOptionLabel={(v) => v?.Description}
                                                                getOptionValue={(v) => v?.BoloTypeID}
                                                                onChange={(e) => handleBoloState("TypeOfBolo", e?.BoloTypeID)}
                                                                placeholder="Select..."
                                                                className="w-100"
                                                            />
                                                            <div className="col-1 d-flex justify-content-end align-items-center">
                                                                <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">CAD Event #</label>
                                                            </div>
                                                            <Select
                                                                name="IncidentID"
                                                                styles={customStylesWithFixedHeight}
                                                                isClearable
                                                                options={incidentData}
                                                                value={boloState?.IncidentID ? incidentData?.find((i) => i?.IncidentID === boloState?.IncidentID) : ""}
                                                                getOptionLabel={(v) => v?.CADIncidentNumber}
                                                                getOptionValue={(v) => v?.IncidentID}
                                                                onChange={(e) => handleBoloState("IncidentID", e?.IncidentID)}
                                                                placeholder="Select..."
                                                                className="w-100"
                                                            />
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-border ml-1"
                                                                style={{ width: "85px" }}
                                                                data-toggle="modal"
                                                                data-target="#CallTakerModal"
                                                                onClick={() => setCallTakerModal(true)}
                                                            >
                                                                <i className="fa fa-plus"></i>
                                                            </button>
                                                            <div className="col-1 d-flex justify-content-end align-items-center">
                                                                <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Priority</label>
                                                            </div>
                                                            <Select
                                                                isClearable
                                                                options={prioritiesDropDown}
                                                                placeholder="Select..."
                                                                styles={customStylesWithFixedHeight}

                                                                getOptionLabel={(v) => `${v?.PriorityCode} | ${v?.Description}`}
                                                                getOptionValue={(v) => v?.PriorityCode}
                                                                formatOptionLabel={(option, { context }) => {
                                                                    return context === 'menu'
                                                                        ? `${option?.PriorityCode} | ${option?.Description}`
                                                                        : option?.PriorityCode;
                                                                }}
                                                                className="w-100"
                                                                name="Priority"
                                                                value={boloState.Priority ? prioritiesDropDown?.find((i) => i?.PriorityID === boloState.Priority) : ""}
                                                                onChange={(e) => {
                                                                    handleBoloState("Priority", e?.PriorityID)
                                                                }}
                                                                onInputChange={(inputValue, actionMeta) => {
                                                                    if (inputValue.length > 12) {
                                                                        return inputValue.slice(0, 12);
                                                                    }
                                                                    return inputValue;
                                                                }}
                                                            />
                                                            <div className="col-1 d-flex justify-content-end align-items-center">
                                                                <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Zone</label>
                                                            </div>
                                                            <Select
                                                                name="Zone"
                                                                styles={customStylesWithFixedHeight}

                                                                isClearable
                                                                options={zoneDropDown}
                                                                value={boloState?.Zone ? zoneDropDown?.find((i) => i?.value === boloState?.Zone) : ""}
                                                                onChange={(e) => handleBoloState("Zone", e?.value)}
                                                                placeholder="Select..."
                                                                className="w-100"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Message Field */}
                                                    <div className="tab-form-row">
                                                        <div className="col-1 d-flex align-self-center justify-content-end ">
                                                            <label for="" className="tab-form-label">Message  {errorState.Message && isEmpty(boloState?.Message) && (
                                                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Message"}</p>
                                                            )}</label>
                                                        </div>
                                                        <div className="col-11">
                                                            <textarea
                                                                name="Message"
                                                                type="text"
                                                                rows="3"
                                                                className="form-control  py-1 new-input requiredColor"
                                                                style={{ height: "auto", overflowY: "scroll" }}
                                                                placeholder="Message"
                                                                value={boloState?.Message}
                                                                onChange={(e) => {
                                                                    handleBoloState("Message", e.target.value)
                                                                    e.target.style.height = "auto";
                                                                    const maxHeight = 3 * parseFloat(getComputedStyle(e.target).lineHeight);
                                                                    e.target.style.height = `${Math.min(e.target.scrollHeight, maxHeight)}px`;
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* Image Upload Section */}
                                                    <div className="tab-form-row">
                                                        <div className="col-1 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 mt-2 text-nowrap">Upload File</label>
                                                        </div>
                                                        <div className="col-11 text-field">
                                                            <input
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                multiple
                                                                onChange={handleImageChange}
                                                                ref={fileInputRef}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Additional Form Fields */}
                                                    <div className="tab-form-row">
                                                        <div className="col-1 d-flex justify-content-end mt-1">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 mt-2 text-nowrap">Delete After</label>
                                                        </div>
                                                        <div className="col-1 text-field">
                                                            <input
                                                                type="number"
                                                                className="form-control py-1 new-input"
                                                                name="DeleteAfter"
                                                                placeholder="Delete After"
                                                                value={boloState.DeleteAfter}
                                                                onChange={(v) => {
                                                                    const value = v.target.value;
                                                                    if (/^\d*$/.test(value) && value.length <= 3) {
                                                                        handleBoloState("DeleteAfter", value);
                                                                    }
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                maxLength={3}
                                                            />

                                                        </div>
                                                        <div className="col-1 d-flex justify-content-start mt-1">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 mt-2 text-nowrap">Days</label>
                                                        </div>
                                                    </div>
                                                    <div className="tab-form-row col-11 offset-1">
                                                        <div className="cad-images image-preview cursor pointer">
                                                            {selectedImages.map((image, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="cad-images image-container"
                                                                    data-toggle="modal"
                                                                    data-target="#ViewSingleImageModal"
                                                                    onClick={() => {
                                                                        setViewSingleImage(image);
                                                                        setIsOpenViewSingleImageModal(true);
                                                                    }}
                                                                >
                                                                    {image.BoloID ? (
                                                                        <img
                                                                            src={image.FileAttachment}
                                                                            alt={`Selected ${index}`}
                                                                            width="100"
                                                                            height="100"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={URL.createObjectURL(image)}
                                                                            alt={`Selected ${index}`}
                                                                            width="100"
                                                                            height="100"
                                                                        />
                                                                    )}
                                                                    <button
                                                                        className="delete-button"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation(); // Prevents the parent click handler from firing
                                                                            setConfirmParams({ index, image }); // Pass the parameters to use later
                                                                            setShowConfirmModal(true); // Open the modal
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {/* Action Buttons */}
                                                    <div className="row">
                                                        <div className="col-12 p-0">
                                                            <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                                                <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                                    {!!boloState?.BoloID && <button type="button" className="cancel-button" data-toggle="modal"
                                                                        data-target="#BoloCloseModal" onClick={() => { setOpenCloseBolo(true); }}>Close Bolo</button>}
                                                                    <button type="button" className="save-button ml-2" data-toggle="modal"
                                                                        data-target="#BoloSearchModal" onClick={() => { setOpenBoloSearchModal(true); handelClear(); }}>Search</button>
                                                                    <button
                                                                        type="button"
                                                                        className="save-button"
                                                                        disabled={isLoading || (!!boloState?.BoloID && !isDataUpdated)}
                                                                        onClick={() => handleSave()}
                                                                    >
                                                                        {!!boloState?.BoloID ? "Update" : "Save"}
                                                                    </button>
                                                                    <button type="button" className="cancel-button" onClick={() => handelClear()}>Clear</button>
                                                                    <button type="button" data-dismiss="modal" className="cancel-button" onClick={() => handleCancel()}>Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="dropdown d-flex justify-content-end align-items-center pb-1">
                                            <div className='d-flex align-content-center justify-content-end table-header-buttons ml-2'>
                                                <label className="tab-form-label d-flex justify-content-end mr-1 mt-2 text-nowrap">Type Of BOLO</label>
                                                <Select
                                                    name="typeOfBolo"
                                                    styles={{
                                                        ...colourStyles,
                                                        container: (base) => ({
                                                            ...base,
                                                            width: 200,
                                                        }),
                                                        menu: (base) => ({
                                                            ...base,
                                                            // zIndex: 1050,
                                                        }),
                                                    }}
                                                    isClearable
                                                    options={typeOFBOLO}
                                                    value={typeOfBoloFilter ? typeOFBOLO?.find((i) => i?.BoloTypeID === typeOfBoloFilter) : ""}
                                                    getOptionLabel={(v) => v?.Description}
                                                    getOptionValue={(v) => v?.BoloTypeID}
                                                    onChange={(e) => setTypeOfBoloFilter(e?.BoloTypeID)}
                                                    placeholder="Select..."
                                                    menuPlacement="top"
                                                />
                                                <label className="tab-form-label d-flex justify-content-end mr-1 mt-2 text-nowrap">Priority</label>
                                                <Select
                                                    name="priority"
                                                    styles={{
                                                        ...colourStyles,
                                                        container: (base) => ({
                                                            ...base,
                                                            width: 200,
                                                        }),
                                                        menu: (base) => ({
                                                            ...base,
                                                            // zIndex: 1050,
                                                        }),
                                                    }}
                                                    isClearable
                                                    options={prioritiesDropDown}
                                                    formatOptionLabel={(option, { context }) => {
                                                        return context === 'menu'
                                                            ? `${option?.PriorityCode} | ${option?.Description}`
                                                            : option?.PriorityCode;
                                                    }}
                                                    value={priorityFilter ? prioritiesDropDown?.find((i) => i?.PriorityID === priorityFilter) : ""}
                                                    getOptionLabel={(v) => `${v?.PriorityCode} | ${v?.Description}`}
                                                    getOptionValue={(v) => v?.PriorityCode}
                                                    onChange={(e) => { setPriorityFilter(e?.PriorityID) }}
                                                    placeholder="Select..."
                                                    menuPlacement="top"
                                                />
                                            </div>
                                        </div>
                                        {/* Data Table */}
                                        <div className="table-responsive CAD-table" style={{ position: "sticky" }}>
                                            <DataTable
                                                dense
                                                columns={columns}
                                                data={boloList}
                                                customStyles={tableCustomStyles}
                                                pagination
                                                responsive
                                                striped
                                                highlightOnHover
                                                fixedHeader
                                                selectableRowsHighlight
                                                noDataComponent={isNoData ? "There are no data to display" : 'There are no data to display'}
                                                fixedHeaderScrollHeight="190px"
                                                persistTableHead={true}
                                                onRowClicked={(row) => {
                                                    handelSetEditData(row); setClickedRow(row);
                                                }}
                                                conditionalRowStyles={conditionalRowStyles}

                                            />
                                        </div>
                                    </>}
                                {showPage === 'auditLog' && <div className="table-responsive  mt-2" style={{ position: "sticky" }}>
                                    <DataTable
                                        dense
                                        columns={columns2}
                                        data={contactList}
                                        customStyles={tableCustomStyles}
                                        pagination
                                        responsive
                                        striped
                                        highlightOnHover
                                        fixedHeader
                                        selectableRowsHighlight
                                        fixedHeaderScrollHeight="190px"
                                        persistTableHead={true}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                </dialog>
            ) : null}
            <ModalConfirm
                showModal={showConfirmModal}
                setShowModal={setShowConfirmModal}
                confirmAction=""
                handleConfirm={() => {
                    removeImage(confirmParams.index, confirmParams.image);
                    setIsDataUpdated(true)
                    setShowConfirmModal(false);
                }}
                isCustomMessage
                message="Are you sure you want to delete this item ?"
            />

            <BoloSearchModal {...{ openBoloSearchModal, setOpenBoloSearchModal }} />
            <CloseBoloModal {...{ openCloseBolo, setOpenCloseBolo, boloState, refetch, clearBoloState, clearErrorState }} />
            <CallTakerModal
                {...{
                    openCallTakerModal,
                    setCallTakerModal,
                    setIncNo,
                    // isIncidentDispatch,
                    // setIsIncidentDispatch
                }}
            />
            <ViewImageModal imageModalStatus={imageModalStatus} setImageModalStatus={setImageModalStatus} viewImg={viewImg} />
            <ViewSingleImageModal isOpenViewSingleImageModal={isOpenViewSingleImageModal} setIsOpenViewSingleImageModal={setIsOpenViewSingleImageModal} viewSingleImage={viewSingleImage} viewBoloImage={viewBoloImage} />
        </>
    );
};

export default memo(BoloModal);
