import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import Dropdown from 'react-bootstrap/Dropdown';
import { useQuery } from 'react-query';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import { coloredStyle_Select } from '../../Utility/CustomStylesForReact';
import '../section.css';
import useObjState from '../../../CADHook/useObjState';
import MasterTableListServices from '../../../CADServices/APIs/masterTableList'
import { isEmpty, compareStrings } from '../../../CADUtils/functions/common';
import { toastifySuccess } from '../../../Components/Common/AlertMsg';
import { SearchFilter, SendIcon } from '../../Common/SearchFilter'
import CADConfirmModal from '../../Common/CADConfirmModal';
import { useSelector } from 'react-redux';

const ZoneSection = () => {
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [filterListData, setFilterListData] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [listData, setListData] = useState([]);
  const [searchValue1, setSearchValue1] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [isActive, setIsActive] = useState('');
  const [confirmType, setConfirmType] = useState('');
  const [activeInactiveData, setActiveInactiveData] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [agencyCodeDropDown, setAgencyCodeDropDown] = useState([])
  const [loginPinID, setLoginPinID] = useState('');

  const [
    zoneState,
    setZoneState,
    handleZoneState,
    clearZoneState,
  ] = useObjState({
    ZoneID: "",
    AgencyCode: [],
    ZoneCode: "",
    ZoneDescription: "",
    IsActive: 1,
  })

  const [
    errorZoneState,
    _setErrorZoneState,
    handleErrorZoneState,
    clearStateZoneState,
  ] = useObjState({
    AgencyCode: false,
    ZoneCode: false,
    ZoneDescription: false
  });

  const validateZoneForm = () => {
    let isError = false;
    const keys = Object.keys(errorZoneState);
    keys.map((field) => {
      if (
        field === "AgencyCode" &&
        isEmpty(zoneState[field])) {
        handleErrorZoneState(field, true);
        isError = true;
      } else if (field === "ZoneCode" && isEmpty(zoneState[field])) {
        handleErrorZoneState(field, true);
        isError = true;
      } else if (field === "ZoneDescription" && isEmpty(zoneState[field])) {
        handleErrorZoneState(field, true);
        isError = true;
      } else {
        handleErrorZoneState(field, false);
      }
    });
    return !isError;
  };

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
    }
  }, [localStoreData]);

  const getAgencyCode = `/MasterAgency/MasterAgencyCode`;
  const { data: agencyCodeData, isSuccess: isFetchAgencyCode } = useQuery(
    [getAgencyCode],
    MasterTableListServices.getAgencyCode,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isFetchAgencyCode && agencyCodeData) {
      const data = JSON.parse(agencyCodeData?.data?.data);
      setAgencyCodeDropDown(data?.Table || [])
    }
  }, [isFetchAgencyCode, agencyCodeData]);

  const getZoneKey = `/CAD/GeoPetrolZone/GetData_Zone`;
  const { data: zoneList, isSuccess: isFetchZoneList, refetch: zoneListRefetch, isError: isNoData } = useQuery(
    [getZoneKey, {
      IsActive: parseInt(pageStatus)
    }],
    MasterTableListServices.getZone,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  useEffect(() => {
    if (isFetchZoneList && zoneList) {
      const res = JSON.parse(zoneList?.data?.data);
      const data = res?.Table
      setFilterListData(data || [])
      setListData(data || [])
    } else {
      setFilterListData([])
      setListData([])
    }
  }, [isFetchZoneList, zoneList])

  function handleClose() {
    clearZoneState();
    clearStateZoneState();
  }

  const onInsertZone = async () => {
    if (!validateZoneForm()) return;
    try {
      const serviceMethod = zoneState?.ZoneID
        ? MasterTableListServices.updateZone
        : MasterTableListServices.insertZone;

      const res = await serviceMethod(zoneState);

      if (res) {
        const message = zoneState?.ZoneID
          ? "Data Updated Successfully"
          : "Data Saved Successfully";

        toastifySuccess(message);
        zoneListRefetch();
        handleClose();
      }
    } catch (error) {
      console.error("Error during insert/update:", error);
    }
  };

  async function handelActiveInactive() {
    const data = {
      ZoneID: activeInactiveData?.ZoneID,
      DeletedByUserFK: loginPinID,
      IsActive: isActive,
    }
    const response = await MasterTableListServices.changeStatusZone(data);
    if (response?.status === 200) {
      const data = JSON.parse(response?.data?.data)?.Table?.[0];
      toastifySuccess(data?.Message);
      zoneListRefetch();
    }
    setShowModal(false);
  }

  const columns = [
    {
      name: 'Agency Code',
      selector: row => row.AgencyCode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.AgencyCode, rowB.AgencyCode),
      style: {
        position: "static",
      },
    },
    {
      name: 'Zone Code',
      selector: row => row.ZoneCode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ZoneCode, rowB.ZoneCode),
      style: {
        position: "static",
      },
    },
    {
      name: 'Description',
      selector: row => row.ZoneDescription,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ZoneDescription, rowB.ZoneDescription),
      width: "60%", // Adjust width as needed
      style: {
        position: "static",
      },
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            className="btn btn-sm text-white px-1 py-0 mr-1"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {row?.IsActive ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(0); setConfirmType("InActive") }}></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(1); setConfirmType("Active"); }}></i>
            )}
          </span>
        </div>,
      width: "70px",
      style: {
        position: "static",
      },
    },

  ];

  const setEditValue = (row) => {
    setZoneState({
      ...row,
      AgencyCode: parseInt(row.AgencyCode),
      IsActive: row.IsActive ? 1 : 0
    });
  }

  const conditionalRowStyles = [
    {
      when: row => row?.ZoneID === zoneState?.ZoneID,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#001f3fbd',
          color: 'white',
        },
      },
    }
  ];


  const handleSpecialKeyDown = (e) => {
    const isAlphanumeric = e.key.length === 1 && e.key.match(/[a-zA-Z0-9]/);
    const isBackspace = e.key === "Backspace";

    if (!isAlphanumeric && !isBackspace) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Agency Code{errorZoneState.AgencyCode && isEmpty(zoneState.AgencyCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Agency Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={coloredStyle_Select}
                          placeholder="Select"
                          options={agencyCodeDropDown}
                          isClearable
                          value={
                            !isEmpty(zoneState.AgencyCode)
                              ? agencyCodeDropDown.find(
                                (option) => option.AgencyID === zoneState.AgencyCode
                              )
                              : []
                          }
                          getOptionLabel={(v) => v?.ShortName}
                          getOptionValue={(v) => v?.AgencyID}
                          onChange={(e) => {
                            handleZoneState("AgencyCode", e?.AgencyID);
                            setIsChange(true);
                          }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                    </div>
                    {/* line 2 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Zone Code{errorZoneState.ZoneCode && isEmpty(zoneState.ZoneCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Zone Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Zone Code'
                          maxLength={10}
                          onKeyDown={handleSpecialKeyDown}
                          value={zoneState.ZoneCode}
                          onChange={(e) => { handleZoneState("ZoneCode", e.target.value); setIsChange(true); }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Description{errorZoneState.ZoneDescription && isEmpty(zoneState.ZoneDescription) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Description"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-4 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Description'
                          value={zoneState.ZoneDescription}
                          onChange={(e) => { handleZoneState("ZoneDescription", e.target.value); setIsChange(true); }}
                        />
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                </>
            }
          </div>

        </div>

        <div className='utilities-tab-content-table-container'>
          <div className="row">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control"
                value={searchValue1}
                placeholder='Search By Code...'
                onChange={(e) => {
                  setSearchValue1(e.target.value);
                  const result = SearchFilter(listData, e.target.value, searchValue2, filterTypeIdOption, 'ZoneCode', 'ZoneDescription')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '2', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control"
                placeholder='Search By Description...'
                value={searchValue2}
                onChange={(e) => {
                  setSearchValue2(e.target.value);
                  const result = SearchFilter(listData, searchValue1, e.target.value, filterTypeDescOption, 'ZoneCode', 'ZoneDescription')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="table-responsive">
            <DataTable
              dense
              columns={columns}
              data={filterListData}
              customStyles={tableCustomStyles}
              pagination
              responsive
              striped
              conditionalRowStyles={conditionalRowStyles}
              highlightOnHover
              noDataComponent={isNoData ? "There are no data to display" : 'There are no data to display'}
              fixedHeader
              fixedHeaderScrollHeight="360px"
              onRowClicked={(row) => {
                setEditValue(row);
              }}
              selectableRowsHighlight
            />
          </div>

          {parseInt(pageStatus) === 1 && <div className="utilities-tab-content-button-container" >
            <button type="button" className="btn btn-sm btn-success" onClick={() => handleClose()}>New</button>
            <button type="button" disabled={!isChange} className="btn btn-sm btn-success" onClick={() => onInsertZone()}>{!!zoneState?.ZoneID ? "Update" : "Save"}</button>
          </div>}
        </div>
      </div>
      <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} />
    </>
  );
};

export default ZoneSection;