import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import Dropdown from 'react-bootstrap/Dropdown';
import { Filter, SendIcon } from '../../../Components/Filter/Filter';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import { coloredStyle_Select, colorLessStyle_Select } from '../../Utility/CustomStylesForReact';
import '../section.css';
import MasterTableListServices from "../../../CADServices/APIs/masterTableList";
import useObjState from '../../../CADHook/useObjState';
import { dropDownDataModel, isEmpty, compareStrings } from '../../../CADUtils/functions/common';
import { useQuery } from 'react-query';
import CallTakerServices from "../../../CADServices/APIs/callTaker";
import { toastifySuccess } from '../../../Components/Common/AlertMsg';
import CADConfirmModal from '../../Common/CADConfirmModal';
import { useSelector } from 'react-redux';

const CallForServiceCodeSection = () => {
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [prioritiesDropDown, setPrioritiesDropDown] = useState([]);
  const [filterListData, setFilterListData] = useState([]);
  const [listData, setListData] = useState([]);
  const [searchValue1, setSearchValue1] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [activeInactiveData, setActiveInactiveData] = useState({})
  const [confirmType, setConfirmType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isActive, setIsActive] = useState('');
  const [loginPinID, setLoginPinID] = useState('');
  const [loginAgencyID, setLoginAgencyID] = useState('');

  const [
    CFSCodeState,
    setCFSCodeState,
    handleCFSCodeState,
    clearCFSCodeState,
  ] = useObjState({
    cfsCode: "",
    cfsCodeDesc: "",
    priorityCode: "",
    alarmLevel: "",
    cfsLaw: false,
    cfsFire: false,
    cfsEmergencyMedicalService: false,
    cfsOther: false,
    CaseRequired: false,
    IsActive: 1
  })

  const [
    errorCFSCodeState,
    _setErrorCFSCodeState,
    handleErrorCFSCodeState,
    clearStateCFSCodeState,
  ] = useObjState({
    cfsCode: false,
    cfsCodeDesc: false,
    priorityCode: false,
    agencyTypes: false,
  });

  const GetResourceTypeKey = `/CAD/MasterCallforServiceCode/InsertCallforServiceCode`;
  const { data: getCFSData, isSuccess: isFetchCFSData, refetch, isError: isNoData } = useQuery(
    [GetResourceTypeKey, { Action: "GET", IsActive: parseInt(pageStatus) },],
    MasterTableListServices.getCFS,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  useEffect(() => {
    if (getCFSData && isFetchCFSData) {
      const data = JSON.parse(getCFSData?.data?.data);
      setFilterListData(data?.Table)
      setListData(data?.Table)
    } else {
      setFilterListData([])
      setListData([])
    }
  }, [getCFSData, isFetchCFSData])

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
      setLoginAgencyID(localStoreData?.AgencyID);

    }
  }, [localStoreData]);

  const getPriorityKey = `/CAD/MasterPriority/GetMasterPriority`;
  const { data: prioritiesData, isSuccess: isFetchPrioritiesData } = useQuery(
    [getPriorityKey, {
      IsActive: 1,
      AgencyID: loginAgencyID,
    }],
    MasterTableListServices.getMasterPriority,
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!loginAgencyID,
    }
  );

  useEffect(() => {
    if (isFetchPrioritiesData && prioritiesData) {
      const data = JSON.parse(prioritiesData?.data?.data);
      setPrioritiesDropDown(data?.Table);
    }
  }, [isFetchPrioritiesData, prioritiesData]);

  async function handelActiveInactive() {
    const data = {
      CallforServiceID: activeInactiveData?.CallforServiceID,
      DeletedByUserFK: loginPinID,
      IsActive: isActive,
    }
    const response = await MasterTableListServices.changeStatusCallforServiceCode(data);
    if (response?.status === 200) {
      const data = JSON.parse(response?.data?.data)?.Table?.[0];
      toastifySuccess(data?.Message);
      refetch();
    }
    setShowModal(false);
  }

  const columns = [
    {
      name: 'CFS Code',
      selector: row => row.CFSCODE,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CFSCODE, rowB.CFSCODE),
      style: {
        position: "static",
      },
    },
    {
      name: 'Description',
      selector: row => row.CFSCodeDescription,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CFSCodeDescription, rowB.CFSCodeDescription),
      style: {
        position: "static",
      },
    },
    {
      name: 'Alarm Level',
      // selector: row => prioritiesDropDown?.find((i) => i?.PriorityID == row?.AlrmLevel)?.label,
      style: {
        position: "static",
      },
    },
    {
      name: 'RMS Incident #',
      selector: row => row?.CaseRequired ? "Yes" : "No",
      style: {
        position: "static",
      },
    },
    {
      name: 'Required Agency Type',
      selector: row => {
        let result = [];
        if (row?.LAW === true) result.push("L");
        if (row?.FIRE === true) result.push("F");
        if (row?.EMERGENCYMEDICALSERVICE === true) result.push("E");
        if (row?.OTHER === true) result.push("O");
        return result.join(", ") || "";
      },
      sortable: true,
      style: {
        position: "static",
      },
    },
    {
      name: 'Priority Code',
      selector: row => prioritiesDropDown?.find((i) => i?.PriorityID === row?.PriorityID)?.Description,
      sortable: true,
      style: {
        position: "static",
      },
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            className="btn btn-sm text-white px-1 py-0 mr-1"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {row?.IsActive ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(0); setConfirmType("InActive") }}></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(1); setConfirmType("Active"); }}></i>
            )}
          </span>
        </div>,
      width: "70px",
      style: {
        position: "static",
      },
    },
  ];

  function handelCancel() {
    clearStateCFSCodeState();
    clearCFSCodeState();
  }

  const handleSpecialKeyDown = (e) => {
    const isAlphanumeric = e.key.length === 1 && e.key.match(/[a-zA-Z0-9]/);
    const isBackspace = e.key === "Backspace";

    if (!isAlphanumeric && !isBackspace) {
      e.preventDefault();
    }
  };

  const validateCFSCodeForm = () => {
    let isError = false;
    const keys = Object.keys(errorCFSCodeState);
    keys.map((field) => {
      if (
        field === "cfsCode" &&
        isEmpty(CFSCodeState[field])) {
        handleErrorCFSCodeState(field, true);
        isError = true;
      } else if (field === "cfsCodeDesc" && isEmpty(CFSCodeState[field])) {
        handleErrorCFSCodeState(field, true);
        isError = true;
      } else if (field === "priorityCode" && isEmpty(CFSCodeState[field])) {
        handleErrorCFSCodeState(field, true);
        isError = true;
      } else if (field === "agencyTypes" && (CFSCodeState.cfsLaw === (false || 0) && CFSCodeState.cfsFire === (false || 0) && CFSCodeState.cfsEmergencyMedicalService === (false || 0) && CFSCodeState.cfsOther === (false || 0))) {
        handleErrorCFSCodeState(field, true);
        isError = true;
      } else {
        handleErrorCFSCodeState(field, false);
      }
    });
    return !isError;
  };

  const onSave = async () => {
    if (!validateCFSCodeForm()) return

    const isUpdate = !!CFSCodeState?.CallforServiceID;
    const payload = {
      Action: isUpdate ? "UPDATE" : "INSERT",
      CallforServiceID: isUpdate ? CFSCodeState?.CallforServiceID : undefined,
      CFSCODE: CFSCodeState?.cfsCode,
      CFSCodeDescription: CFSCodeState?.cfsCodeDesc,
      Prioritycode: CFSCodeState?.priorityCode?.PriorityID,
      AlrmLevel: CFSCodeState?.alarmLevel?.value,
      LAW: CFSCodeState?.cfsLaw ? 1 : 0,
      FIRE: CFSCodeState?.cfsFire ? 1 : 0,
      EmergencyMedicalService: CFSCodeState?.cfsEmergencyMedicalService ? 1 : 0,
      Other: CFSCodeState?.cfsOther ? 1 : 0,
      CaseRequired: CFSCodeState?.CaseRequired ? 1 : 0,
      IsActive: CFSCodeState?.IsActive,
      CreatedByUserFK: isUpdate ? undefined : loginPinID,
      ModifiedByuserFk: isUpdate ? loginPinID : undefined
    };

    const response = await MasterTableListServices.insertCFS(payload);

    if (response?.status === 200) {
      toastifySuccess(isUpdate ? "Data Updated Successfully" : "Data Saved Successfully");
      handelCancel();
      refetch();
    }
  };

  function handelSetEditData(data) {
    setCFSCodeState({
      CallforServiceID: data?.CallforServiceID,
      cfsCode: data?.CFSCODE,
      cfsCodeDesc: data?.CFSCodeDescription,
      priorityCode: data?.PriorityID ? prioritiesDropDown?.find((i) => i?.PriorityID === data?.PriorityID) : "",
      alarmLevel: prioritiesDropDown?.find((i) => i?.PriorityID == data?.AlrmLevel),
      cfsLaw: data?.LAW ? 1 : 0,
      cfsFire: data?.FIRE ? 1 : 0,
      cfsEmergencyMedicalService: data?.EMERGENCYMEDICALSERVICE ? 1 : 0,
      cfsOther: data?.OTHER ? 1 : 0,
      CaseRequired: data?.CaseRequired ? 1 : 0,
      IsActive: data?.IsActive ? 1 : 0
    })
  }

  const conditionalRowStyles = [
    {
      when: row => row?.CallforServiceID === CFSCodeState?.CallforServiceID,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#001f3fbd',
          color: 'white',
        },
      },
    }
  ];

  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          CFS Code{errorCFSCodeState.cfsCode && isEmpty(CFSCodeState.cfsCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter CFS Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='CFS Code'
                          name="cfsCode"
                          onKeyDown={handleSpecialKeyDown}
                          maxLength={10}
                          value={CFSCodeState.cfsCode}
                          onChange={(e) => handleCFSCodeState("cfsCode", e.target.value)}
                        />
                      </div>
                      <div className="col-5 d-flex align-items-center justify-content-between">
                        <label
                          htmlFor=""
                          className="tab-form-label"
                          style={{ whiteSpace: 'nowrap', marginRight: '10px' }} // Ensures label stays on one line
                        >
                          CFS Code Description
                          {errorCFSCodeState.cfsCodeDesc && isEmpty(CFSCodeState.cfsCodeDesc) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>
                              {"Enter Description"}
                            </p>
                          )}
                        </label>
                        <input
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder="CFS Code Description"
                          name="cfsCodeDesc"
                          value={CFSCodeState.cfsCodeDesc}
                          onChange={(e) => handleCFSCodeState("cfsCodeDesc", e.target.value)}
                          style={{ flex: '1' }}
                        />
                      </div>
                    </div>
                    {/* line 2 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-start justify-content-end">
                        <label for="" className="tab-form-label" style={{ marginTop: "10px" }}>
                          Priority Code{errorCFSCodeState.priorityCode && isEmpty(CFSCodeState.priorityCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Priority Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-start justify-content-end">
                        <Select
                          isClearable
                          options={prioritiesDropDown}
                          placeholder="Select..."
                          styles={coloredStyle_Select}
                          getOptionLabel={(v) => `${v?.PriorityCode} | ${v?.Description}`}
                          getOptionValue={(v) => v?.PriorityCode}
                          formatOptionLabel={(option, { context }) => {
                            return context === 'menu'
                              ? `${option?.PriorityCode} | ${option?.Description}`
                              : option?.PriorityCode;
                          }}
                          className="w-100"
                          name="priorityCode"
                          value={CFSCodeState.priorityCode ? CFSCodeState.priorityCode : ""}
                          onChange={(e) => { handleCFSCodeState("priorityCode", e) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-start justify-content-end">
                        <label for="" className="tab-form-label" style={{ marginTop: "10px" }}>
                          Alarm Level
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-start justify-content-end" style={{ marginLeft: "13px" }}>
                        <Select
                          isClearable
                          // options={prioritiesDropDown}
                          placeholder="Select..."
                          styles={colorLessStyle_Select}
                          className="w-100"
                          name="alarmLevel"
                          value={CFSCodeState.alarmLevel}
                          onChange={(e) => { handleCFSCodeState("alarmLevel", e) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 d-flex align-self-start justify-content-end">
                        <label for="" className="tab-form-label" style={{ marginTop: "10px", whiteSpace: 'nowrap', marginRight: '10px' }}>
                          Required Agency Types{errorCFSCodeState.agencyTypes && CFSCodeState.cfsLaw === (false || 0) && CFSCodeState.cfsFire === (false || 0) && CFSCodeState.cfsEmergencyMedicalService === (false || 0) && CFSCodeState.cfsOther === (false || 0) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Agency Type"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-7 d-flex align-self-center justify-content-end">

                        <div className='agency-types-checkbox-container'>
                          {/* L : Law */}
                          <div className="agency-checkbox-item">
                            <input
                              type="checkbox"
                              name="cfsLaw"
                              checked={CFSCodeState.cfsLaw}
                              onChange={(e) => { handleCFSCodeState("cfsLaw", e.target.checked) }}
                            />
                            <div className="agency-checkbox-text-container tab-form-label">
                              <span>L</span>
                              <span>|</span>
                              <span>Law</span>
                            </div>
                          </div>
                          {/* F : Fire */}
                          <div className="agency-checkbox-item ">
                            <input
                              type="checkbox"
                              name="cfsFire"
                              checked={CFSCodeState.cfsFire}
                              onChange={(e) => { handleCFSCodeState("cfsFire", e.target.checked) }}
                            />
                            <div className="agency-checkbox-text-container tab-form-label">
                              <span>F</span>
                              <span>|</span>
                              <span>Fire</span>
                            </div>
                          </div>
                          {/* E : Emergency Medical Service */}
                          <div className="agency-checkbox-item">
                            <input
                              type="checkbox"
                              name="cfsEmergencyMedicalService"
                              checked={CFSCodeState.cfsEmergencyMedicalService}
                              onChange={(e) => { handleCFSCodeState("cfsEmergencyMedicalService", e.target.checked) }}
                            />
                            <div className="agency-checkbox-text-container tab-form-label">
                              <span>E</span>
                              <span>|</span>
                              <span>Emergency Medical Service </span>
                            </div>
                          </div>
                          {/* O : Other */}
                          <div className="agency-checkbox-item">
                            <input
                              type="checkbox"
                              name="cfsOther"
                              checked={CFSCodeState.cfsOther}
                              onChange={(e) => { handleCFSCodeState("cfsOther", e.target.checked) }}

                            />
                            <div className="agency-checkbox-text-container tab-form-label">
                              <span>O</span>
                              <span>|</span>
                              <span>Other</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 offset-2 agency-checkbox-item">
                        <input
                          type="checkbox"
                          name="CaseRequired"
                          checked={CFSCodeState.CaseRequired}
                          onChange={(e) => { handleCFSCodeState("CaseRequired", e.target.checked) }}
                        />
                        <div className="agency-checkbox-text-container tab-form-label">
                          <span>Generate RMS Incident#</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                </>
            }
          </div>

        </div>

        <div className='utilities-tab-content-table-container'>
          <div className="row">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control"
                placeholder='Search By Code...'
                value={searchValue1}
                onChange={(e) => {
                  setSearchValue1(e.target.value);
                  const result = Filter(listData, e.target.value, searchValue2, filterTypeIdOption, 'CFSCODE', 'CFSCodeDescription')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end">
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control"
                placeholder='Search By Description...'
                value={searchValue2}
                onChange={(e) => {
                  setSearchValue2(e.target.value);
                  const result = Filter(listData, searchValue1, e.target.value, filterTypeDescOption, 'CFSCODE', 'CFSCodeDescription')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end">
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="table-responsive">
            <DataTable
              dense
              columns={columns}
              data={filterListData}
              customStyles={tableCustomStyles}
              conditionalRowStyles={conditionalRowStyles}
              pagination
              responsive
              striped
              highlightOnHover
              fixedHeaderScrollHeight="360px"
              fixedHeader
              noDataComponent={isNoData ? "There are no data to display" : 'There are no data to display'}
              onRowClicked={(row) => {
                handelSetEditData(row);
              }}
            />
          </div>
          {parseInt(pageStatus) === 1 &&
            <div className="utilities-tab-content-button-container" >
              <button type="button" className="btn btn-sm btn-success" onClick={() => handelCancel()}>New</button>
              <button type="button" className="btn btn-sm btn-success" onClick={() => {
                onSave();
              }}>{!!CFSCodeState?.CallforServiceID ? "Update" : "Save"}</button>
            </div>}
        </div>
      </div>
      <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} />
    </>
  );
};

export default CallForServiceCodeSection;