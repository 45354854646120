import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Aes256Encrypt, base64ToString, getShowingDateText, getShowingMonthDateYear, getShowingWithOutTime, tableCustomStyles } from '../../Common/Utility';
import SealUnsealTab from '../../Utility/Tab/SealUnsealTab';
import Select from "react-select";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toastifyError, toastifySuccess } from '../../Common/AlertMsg';
import { Comman_changeArrayFormat } from '../../Common/ChangeArrayFormat';
import { AddDelete_Img, fetchPostData } from '../../hooks/Api';
import DatePicker from "react-datepicker";
import SelectBox from '../../Common/SelectBox';

const SealUnseal = () => {

    const navigate = useNavigate();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const [loginAgencyID, setloginAgencyID] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [sealUnsealData, setSealUnsealData] = useState("");
    const [nameSearchValue, setNameSearchValue] = useState([]);
    const [loginPinID, setLoginPinID,] = useState('');
    const [ethinicityDrpData, setEthinicityDrpData] = useState([]);
    const [sexIdDrp, setSexIdDrp] = useState([]);
    const [raceIdDrp, setRaceIdDrp] = useState([]);
    const [logData, setLogData] = useState([]);
    const [dateOfbirth, setdateOfbirth] = useState();
    const [clickedRow, setClickedRow] = useState(null);
    const [masterNameID, setMasterNameID] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [IncidentID, setIncidentID] = useState(null);
    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState([]);
    const [ChargeID, setChargeID] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    let DeNameID = 0, DeMasterNameID = 0

    const query = useQuery();
    var IncID = query?.get("IncId");
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    let openPage = query?.get('page');
    var NameID = query?.get("NameID");
    var NameStatus = query?.get("NameStatus");
    var MasterNameID = query?.get("MasterNameID");
    let MstPage = query?.get('page');

    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));
    if (!NameID) NameID = 0;
    else DeNameID = parseInt(base64ToString(NameID));
    if (!MasterNameID) MasterNameID = 0;
    else DeMasterNameID = parseInt(base64ToString(MasterNameID));

    const [value, setValue] = useState({
        'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'DateOfBirth': '', 'SexID': '', 'RaceID': '', 'EthnicityID': '', 'SealUnseal': '',
        'DateTimeSeal': '',
    })

    useEffect(() => {
        if (localStoreData) {
            setloginAgencyID(localStoreData?.AgencyID);
            setLoginPinID(localStoreData?.PINID);
        }
    }, [localStoreData]);


    const getNameSearch = async () => {
        const {
            NameIDNumber, NameIDNumberTo, NameTypeID, NameReasonCodeID, LastName, MiddleName, FirstName, SuffixID, DateOfBirthFrom, DateOfBirthTo, SexID, RaceID, EthnicityID, HairColorID,
            EyeColorID, WeightFrom, WeightTo, SMTTypeID, SMTLocationID, SSN, SMT_Description, IncidentNumber, IncidentNumberTo, ReportedDate, ReportedDateTo, DateOfBirth,
            HeightFrom, HeightTo, AgencyID, PINID, DLNumber, BusinessTypeID, PhoneTypeID, Contact, FaxNumber, RMSCFSCodeID, FBIID, CrimeLocation, OccurredFrom, OccurredFromTo, AgeFrom, AgeTo, AgeUnitID, Local, SBI, FBI, TAX, SPN, Jacket, OCN, State, ComplexionID
        } = value
        const val = {
            'NameIDNumber': NameIDNumber?.trim(), 'NameIDNumberTo': NameIDNumberTo?.trim(), 'NameTypeID': NameTypeID, 'ReasonCodeList': NameReasonCodeID, 'LastName': LastName?.trim(), 'MiddleName': MiddleName?.trim(), 'FirstName': FirstName?.trim(),
            'SuffixID': SuffixID, 'DateOfBirthFrom': DateOfBirthFrom, 'DateOfBirthTo': DateOfBirthTo, 'SexID': SexID, 'RaceID': RaceID, 'EthnicityID': EthnicityID, 'HairColorID': HairColorID,
            'EyeColorID': EyeColorID, 'WeightFrom': WeightFrom, 'WeightTo': WeightTo, 'SMTTypeID': SMTTypeID, 'SMTLocationID': SMTLocationID, 'SSN': SSN, 'SMT_Description': SMT_Description,
            'IncidentNumber': IncidentNumber, 'IncidentNumberTo': IncidentNumberTo, 'ReportedDate': ReportedDate, 'ReportedDateTo': ReportedDateTo, 'DateOfBirth': DateOfBirth,
            'HeightFrom': HeightFrom, 'HeightTo': HeightTo, 'AgencyID': loginAgencyID, 'DLNumber': DLNumber, 'BusinessTypeID': BusinessTypeID, 'PhoneTypeID': PhoneTypeID, 'Contact': Contact, 'FaxNumber': FaxNumber, 'RMSCFSCodeID': RMSCFSCodeID, 'FBIID': FBIID, 'Address': CrimeLocation, 'OccurredFrom': OccurredFrom, 'OccurredFromTo': OccurredFromTo, 'AgeFrom': AgeFrom, 'AgeTo': AgeTo, 'AgeUnitID': AgeUnitID, 'Local': Local, 'SBI': SBI, 'FBI': FBI, 'TAX': TAX, 'SPN': SPN, 'Jacket': Jacket, 'OCN': OCN, 'State': State, 'ComplexionID': ComplexionID,
        }
        console.log(hasValues(val))
        if (hasValues(val)) {
            fetchPostData("MasterName/Search_Name", val).then((res) => {
                if (res.length > 0) {
                    setNameSearchValue(res);
                    // Clear();
                } else {
                    setNameSearchValue([]); toastifyError("Data Not Available");
                }
            })
        }
        else {
            toastifyError("Please Enter Details");
        }

    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'SSN') {
            let ele = value.replace(/\D/g, '');
            if (ele.length === 9) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) { setValue((prevState) => ({ ...prevState, [name]: match[1] + '-' + match[2] + '-' + match[3], })); }
            } else {
                ele = value.split('-').join('').replace(/\D/g, '');
                setValue((prevState) => ({ ...prevState, [name]: ele, }));
            }
        } else {
            if (event) { setValue((prevState) => ({ ...prevState, [name]: value })); }
            else { setValue((prevState) => ({ ...prevState, [name]: null })); }
        }
    };

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({ ...value, [name]: e.value })
        } else {
            setValue({ ...value, [name]: null });
        }
    };

    useEffect(() => {
        if (loginAgencyID) {
            get_Name_Drp_Data(loginAgencyID)
        }
    }, [loginAgencyID])

    function hasValues(obj) {
        for (let key in obj) {
            if (key != 'AgencyID' && key != 'PINID') {
                if (obj[key]) {
                    return true;
                }
            }
        }
        return false;
    }

    const get_Name_Drp_Data = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('MasterName/GetNameDropDown', val).then((data) => {
            if (data) {
                setEthinicityDrpData(Comman_changeArrayFormat(data[0]?.Ethnicity, 'EthnicityID', 'Description'));
                setSexIdDrp(Comman_changeArrayFormat(data[0]?.Gender, 'SexCodeID', 'Description'));
                setRaceIdDrp(Comman_changeArrayFormat(data[0]?.Race, 'RaceTypeID', 'Description'));
            } else {
                setEthinicityDrpData([]); setSexIdDrp([]); setRaceIdDrp([]);
            }
        })
    };

    const Clear = () => {
        setValue({
            ...value,
            'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'DateOfBirth': '', 'SexID': '', 'RaceID': '', 'EthnicityID': '',
        }); setdateOfbirth(''); setNameSearchValue(''); setLogData('')
    }

    const onDashboardClose = () => {
        navigate('/dashboard-page');
    }


    const conditionalRowStyles = [
        {
            when: row => row === clickedRow,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
            },
        },
    ];

    const handleRowSelected = (selectedRows) => {
        if (selectedRows.selectedRows.length > 0) {
            const selectedRow = selectedRows.selectedRows[0];
            setMasterNameID(selectedRow.MasterNameID);
            get_LogData(selectedRow.MasterNameID);
        } else {
            setSelectedRow(null); setMasterNameID(null); setLogData([]); get_LogData('');
        }
    };

    const get_LogData = (MasterNameID) => {
        const val2 = { 'MasterNameID': MasterNameID, }
        fetchPostData("RecordSeal/GetDataNameIncident", val2).then((res) => {
            console.log(res)
            if (res) {
                setLogData(res);
            } else {
                setLogData([]);
            }
        })
    }

    const columns = [
        {
            name: 'Name Number', selector: (row) => row.NameIDNumber, sortable: true
        },
        {
            name: 'Last Name', selector: (row) => row.LastName, sortable: true
        },
        {
            name: 'First Name', selector: (row) => row.FirstName, sortable: true
        },
        {
            name: 'Middle Name', selector: (row) => row.MiddleName, sortable: true
        },
        {
            name: 'Gender', selector: (row) => row.Gender_Description, sortable: true
        },
        {
            name: 'DOB', selector: (row) => row.DateOfBirth ? getShowingWithOutTime(row.DateOfBirth) : " ", sortable: true
        },
        {
            name: 'Race', selector: (row) => row.Race_Description, sortable: true
        },
        {
            name: 'SSN', selector: (row) => row.SSN, sortable: true
        },
    ]

    //-----------------------Select Charges to be Seal/Unseal----------------------------------------------

    // const [RecordSealValue, setRecordSealValue] = useState({
    //     'ChargeID': '', 'officerID': '', 'Status': '', 'DateTimeSeal': '', 'CreatedByUserFK': '', 'file': '',

    // })
    const AddType = [
        { value: 1, label: 'Seal', },
        { value: 2, label: 'Unseal', },
        { value: 3, label: 'both', },
    ]
    useEffect(() => {
        // get_SealUnsealData()
        setIncidentID(IncidentID)
    }, [IncidentID])

    const get_SealUnsealData = (IncidentID) => {
        const val2 = { 'IncidentID': IncidentID, }
        fetchPostData("RecordSeal/Get_ChargebySeal", val2).then((res) => {
            console.log(res)
            if (res) {
                setSealUnsealData(res);
            } else {
                setSealUnsealData([]);
            }
        })
    }


    const columns1 = [
        {
            name: 'Incident Number', selector: (row) => row.IncidentNumber
            , sortable: true
        },
        {
            name: 'Reported Date', selector: (row) => row.ReportedDate ? getShowingMonthDateYear(row.ReportedDate) : " ",
            sortable: true
        },
        {
            name: 'Location', selector: (row) => row.CrimeLocation?.trim(), sortable: true
        },
    ]

    const data = [
        { id: 1, title: 'Beetdlejuice', year: '1988' },
        { id: 2, title: 'Ghostbusters', year: '1984' },
        { id: 1, title: 'Beetdlejuice', year: '1988' },
        { id: 2, title: 'Ghostbusters', year: '1984' }
    ];

    const expandedData = [
        { id: 1, task: 'Task 1', status: 'Completed' },
        { id: 2, task: 'Task 2', status: 'Pending' }
    ];

    const handleRowSelected1 = (selectedRows) => {
        // Get the selected row, if there's any
        const selectedRow = selectedRows.selectedRows[0]; // Assuming you only want to select one row at a time
        if (selectedRow?.ChargeID) {
            console.log(ChargeID)
            setChargeID(selectedRow?.ChargeID);
        }
    };

    const ExpandedComponent = ({ data }) => (
        <DataTable
            dense
            columns={[
                {
                    name: 'Charge Code', selector: row => row?.ChargeCode_Description, sortable: true
                },
                {
                    name: 'Charge Description', selector: row => row?.NIBRS_Description, sortable: true
                },
                {
                    name: 'Event Number', selector: row => row?.ArrestNumber, sortable: true
                },
                // {
                //     name: 'seal/Date', selector: row => row?.DateTimeSeal, sortable: true
                // },
                {
                    width: '200px',
                    name: 'Date/Time',
                    selector: (row) => row.DateTimeSeal ? getShowingDateText(row.DateTimeSeal) : " ",
                    sortable: true
                },
                {
                    width: '120px',
                    name: <p className='text-end' style={{ position: 'absolute', top: 8, }}>File</p>,
                    cell: row =>
                        <div className="div" style={{ position: 'absolute', top: 4, left: 20 }}>
                            <span onClick={() => window.open(row?.Files)} className="btn btn-sm bg-green text-white px-1 py-0" >
                                <i className="fa fa-eye"></i>
                            </span>
                        </div>
                },
                {
                    name: 'Officer Name', selector: row => row?.status, sortable: true
                },
                {

                    name: 'Status', selector: row => row?.Status, sortable: true
                },
            ]
            }
            // data={sealUnsealData}
            data={filteredData}
            onSelectedRowsChange={handleRowSelected1}
            pagination
            selectableRows
            customStyles={tableCustomStyles}
        />
    );

    // const handleUnsealSubmit = () => {
    //     Add_Documents()
    //     setShowModal(false);
    // };
    const [action, setAction] = useState(null);

    const handleUnsealSubmit = (actionType) => {
        setAction(actionType);
        Add_Documents(actionType);
        setShowModal(false);
    };


    const changeHandler = (e) => {
        const files = e.target.files
        setSelectedFile(files)
        const nameArray = []
        for (let name of files) {
            nameArray?.push(name?.name)
        }
        setSelectedFileName(nameArray);
        // setErrors({ ...errors, 'File_Not_Selected': '' })
    };

    const Add_Documents = async (actionType) => {
        const formdata = new FormData();
        const EncFormdata = new FormData();
        const newDoc = [];
        const EncDocs = [];

        // multiple file upload <----
        for (let i = 0; i < selectedFile.length; i++) {
            formdata.append("file", selectedFile[i]);
            EncFormdata.append("file", selectedFile[i]);
        }

        const { officerID, DateTimeSeal, file } = value;
        const Status = actionType === 'seal' ? 'seal' : (actionType === 'unseal' ? 'unseal' : 'unknown');
        // Update status based on action
        // const Status = actionType === 'seal' ? true : false;
        const val = {
            'ChargeID': ChargeID,
            'CreatedByUserFK': loginPinID,
            'officerID': officerID,
            'Status': Status,
            'file': file, 'DateTimeSeal': getShowingDateText(new Date()),

        };

        const values = JSON.stringify(val);
        newDoc.push(values);
        const EncPostData = await Aes256Encrypt(JSON.stringify([JSON.stringify(val)]));
        EncDocs.push(EncPostData);
        formdata.append("Data", JSON.stringify(newDoc));
        EncFormdata.append("Data", EncDocs);

        AddDelete_Img('RecordSeal/Insert_RecordSeal', formdata, EncFormdata)
            .then((res) => {
                if (res.success) {
                    const parsedData = JSON.parse(res.data);
                    const message = parsedData.Table[0].Message;
                    toastifySuccess(message);
                    setSelectedFileName([]);
                    setSelectedFile([]);
                    get_SealUnsealData(IncidentID)
                } else {
                    console.log("something went wrong");
                }
            })
            .catch(err => console.log(err));
    };

    const handleRowSelected2 = (selectedRows) => {
        const selectedRow = selectedRows.selectedRows[0];
        if (selectedRow?.IncidentID) {
            console.log(IncidentID)
            setIncidentID(selectedRow?.IncidentID);
            get_SealUnsealData(selectedRow?.IncidentID);

        }
    };

    useEffect(() => {
        if (value.SealUnseal) {
            const filtered = sealUnsealData.filter(row => {
                if (value.SealUnseal === 1) return row.Status === 'seal'; // For Seal
                if (value.SealUnseal === 2) return row.Status === 'unseal'; // For Unseal
                return true; // For both
            });
            setFilteredData(filtered);
        } else {
            setFilteredData(sealUnsealData); // No filter, show all
        }
    }, [value.SealUnseal, sealUnsealData]);

    return (
        <>
            <div className="section-body view_page_design pt-1 p-1 bt">
                <div className="div">
                    <div className="col-12 inc__tabs">
                        <SealUnsealTab />
                    </div>
                    <div className="dark-row">
                        <div className="col-12 col-sm-12">
                            <div className="card Agency incident-card">
                                <div className="card-body">
                                    <div className="col-12 col-md-12 col-lg-12 ">
                                        <div className="row " style={{ marginTop: '-10px' }}>
                                            <div className="col-2 col-md-2 col-lg-1  mt-2">
                                                <label htmlFor="" className='new-label  '>Last Name</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3  text-field mt-1">
                                                <input type="text" name='LastName' autoComplete='off' value={value.LastName} onChange={(e) => { handleChange(e) }} />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1  mt-2">
                                                <label htmlFor="" className='new-label '>First Name</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3  text-field  mt-1">
                                                <input type="text" name='FirstName' autoComplete='off' value={value.FirstName} onChange={(e) => { handleChange(e) }} />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1  mt-2 px-0">
                                                <label htmlFor="" className='new-label px-0'>Middle&nbsp;Name</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3  text-field  mt-1">
                                                <input type="text" name='MiddleName' autoComplete='off' value={value.MiddleName} onChange={(e) => { handleChange(e) }} />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'>Gender</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1">
                                                <Select
                                                    // styles={isAdult ? colourStyles : customStylesWithOutColor}
                                                    name='SexID'
                                                    value={sexIdDrp?.filter((obj) => obj.value === value?.SexID)}
                                                    options={sexIdDrp}
                                                    onChange={(e) => ChangeDropDown(e, 'SexID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                // isDisabled={nameTypeCode === "B" ? true : false}
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                <label htmlFor="" className='new-label'>DOB</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 ">
                                                <DatePicker
                                                    id='DateOfBirth'
                                                    name='DateOfBirth'
                                                    className=''
                                                    onChange={(date) => {
                                                        setdateOfbirth(date); setValue({ ...value, ['DateOfBirth']: date ? getShowingMonthDateYear(date) : null, });
                                                    }}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                    isClearable={value?.DateOfBirth ? true : false}
                                                    selected={dateOfbirth}
                                                    placeholderText={value?.DateOfBirth ? value?.DateOfBirth : 'Select...'}
                                                    maxDate={new Date()}
                                                    // showTimeSelect
                                                    timeIntervals={1}
                                                    // timeCaption="Time"
                                                    autoComplete="Off"

                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1  mt-2">
                                                <label htmlFor="" className='new-label '>SSN</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3  text-field  mt-1">
                                                <input type="text" name='SSN' autoComplete='off' value={value.SSN} maxLength={9} onChange={(e) => { handleChange(e) }} />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'>Race</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1">
                                                <Select
                                                    name='RaceID'
                                                    value={raceIdDrp?.filter((obj) => obj.value === value?.RaceID)}
                                                    options={raceIdDrp}
                                                    onChange={(e) => ChangeDropDown(e, 'RaceID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                // isDisabled={nameTypeCode === "B" ? true : false}
                                                // styles={isAdult ? colourStyles : customStylesWithOutColor}
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                                <label htmlFor="" className='new-label'>Ethnicity</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1">
                                                <Select
                                                    name='EthnicityID'
                                                    value={ethinicityDrpData?.filter((obj) => obj.value === value?.EthnicityID)}
                                                    options={ethinicityDrpData}
                                                    onChange={(e) => ChangeDropDown(e, 'EthnicityID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                // styles={customStylesWithOutColor}
                                                />
                                            </div>
                                            <div className="btn-box col-4  text-right   mb-1 mt-3" >
                                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, value.RaceID, value.EthnicityID, true)}>Search</button>
                                                <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={onDashboardClose} >Close</button>
                                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={Clear}>Clear</button>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-1">
                                        <DataTable
                                            dense
                                            columns={columns}
                                            data={nameSearchValue}
                                            onSelectedRowsChange={handleRowSelected}
                                            pagination
                                            conditionalRowStyles={conditionalRowStyles}
                                            onRowClicked={(row) => {
                                                setClickedRow(row);
                                                // set_Edit_Value(row);
                                                if (row?.MasterNameID) {
                                                    setMasterNameID(row?.MasterNameID)
                                                    setIncidentID(row?.IncidentID)
                                                    get_LogData(row?.MasterNameID);
                                                }
                                            }}
                                            highlightOnHover
                                            fixedHeaderScrollHeight="150px"
                                            fixedHeader
                                            persistTableHead={true}
                                            customStyles={tableCustomStyles}
                                        />
                                    </div>
                                    <div className="col-12 bg-line py-1 d-flex justify-content-between align-items-center mt-1 text-white mt-3">
                                        <p className="p-0 m-0">Select Charges to be Seal/Unseal</p>
                                        <div className="ml-auto" style={{ width: '200px' }}>
                                            {/* <SelectBox
                                                name='SealUnseal'
                                                options={AddType}
                                                // styles={colourStyles}
                                                isClearable
                                                placeholder="Filter..."
                                                value={AddType?.filter((obj) => obj.value === value?.SealUnseal)}
                                                onChange={(selectedOption) => {
                                                    setValue({ ...value, ['SealUnseal']: selectedOption ? selectedOption.value : '' });
                                                }}
                                            /> */}
                                            <SelectBox
                                                name='SealUnseal'
                                                options={AddType}
                                                isClearable
                                                placeholder="Filter..."
                                                value={AddType?.filter((obj) => obj.value === value?.SealUnseal)}
                                                onChange={(selectedOption) => {
                                                    setValue({ ...value, ['SealUnseal']: selectedOption ? selectedOption.value : '' });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-1">
                                        <DataTable
                                            dense
                                            columns={columns1}
                                            data={logData}
                                            pagination
                                            // conditionalRowStyles={conditionalRowStyles}
                                            highlightOnHover
                                            fixedHeaderScrollHeight="1500px"
                                            fixedHeader
                                            // onRowClicked={(row) => {
                                            //     setClickedRow(row);
                                            //     if (row?.IncidentID) {
                                            //         setIncidentID(row?.IncidentID)
                                            //         get_SealUnsealData(row?.IncidentID);
                                            //     }
                                            // }}
                                            onSelectedRowsChange={handleRowSelected2}
                                            persistTableHead={true}
                                            customStyles={tableCustomStyles}
                                            selectableRows
                                            expandableRows
                                            expandableRowsComponent={({ data }) => <ExpandedComponent data={data} />}
                                            expandableInheritConditionalStyles
                                        />
                                    </div>
                                    <div className="btn-box text-right mr-1 mt-2">
                                        {/* <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1">Clear</button> */}
                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => setShowModal(true)}>
                                            Seal
                                        </button>
                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => setShowModal(true)}>
                                            UnSeal
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for UnSeal */}
            {showModal && (
                <div className="modal fade show" style={{ background: "rgba(0,0,0, 0.5)", display: 'block' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">UnSeal Charges</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12 col-md-12 col-lg-12 text-field ">
                                    <input type="file" name='file' onChange={changeHandler} required />
                                    <label htmlFor="sealUnsealData" className='new-label '>Enter Details to UnSeal</label>
                                    {selectedFileName?.length > 0 &&
                                        <i className="fa fa-close" style={{ position: "absolute", right: "1rem", top: "7px" }} onClick={() => { setSelectedFileName(''); document.querySelector("input[type='file']").value = "" }}></i>}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => handleUnsealSubmit('seal')}>Seal</button>
                                <button type="button" className="btn btn-primary" onClick={() => handleUnsealSubmit('unseal')}>UnSeal</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShowModal(false)}>Close</button>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SealUnseal;
