import React, { useContext, useState, useEffect } from 'react';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { AgencyContext } from '../../../../Context/Agency/Index';
import { getShowingDateText, getShowingMonthDateYear, filterPassedTime, Decrypt_Id_Name, DecryptedList, EncryptedList, base64ToString, stringToBase64, filterPassedDateTime, formatDate, filterPassedTimeZone, } from '../../../Common/Utility';
import { AddDeleteUpadate, ScreenPermision, fetchDate, fetchPostData } from '../../../hooks/Api';
import { toastifyError, toastifySuccess } from '../../../Common/AlertMsg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RequiredFieldIncident, Space_NotAllow, checkDateIsAfter } from '../../Utility/Personnel/Validation';
import { Comman_changeArrayFormat, threeColArray, threeColArrayWithCode, } from '../../../Common/ChangeArrayFormat';
import VerifyLocation from './VerifyLocation/VerifyLocation';
import IdentifyFieldColor from '../../../Common/IdentifyFieldColor';
import Location from '../../../Location/Location';
// import { IncListDropDownArray } from '../../../Utility/ListDropDownArray/ListDropArray';
import Loader from '../../../Common/Loader';
import ChangesModal from '../../../Common/ChangesModal';
import { useDispatch, useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import fs from 'fs';
import FileSaver from 'file-saver';
import moment from 'moment';
import { getMinutes, setHours, setMinutes } from 'date-fns';
import ListModal from '../../Utility/ListManagementModel/ListModal';
import { Incident_ReportDate } from '../../../../redux/actionTypes';
import { get_CadCfsCode_Drp_Data, get_Cad_Disposition_Drp_Data, get_FBI_Codes_Drp_Data, get_Incident_Drp_Data, get_ReceiveSource_Drp_Data, get_Rms_Disposition_Drp_Data } from '../../../../redux/actions/DropDownsData';
import UCR7Report from './UCR7SexualAsaultReport';
import UCR10Report from './UCR10FamilyViolenceReport';
import HateCrimeIncReport from './UCR23HateCrimeReport';
import { checkValidOffenderError, ErrorTooltip } from '../../../NIBRSError';


const IncidentHome = ({ incidentReportedDate, setIncidentReportedDate }) => {

    let navigate = useNavigate();
    const dispatch = useDispatch()
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);

    const receiveSourceDrpData = useSelector((state) => state.DropDown.receiveSourceDrpData);
    const fbiCodesDrpData = useSelector((state) => state.DropDown.fbiCodesDrpData);
    const cadCfsCodeDrpData = useSelector((state) => state.DropDown.cadCfsCodeDrpData);
    const rmsDispositionDrpData = useSelector((state) => state.DropDown.rmsDispositionDrpData);
    const cadDispositionDrpData = useSelector((state) => state.DropDown.cadDispositionDrpData);
    const newYorkTime = useSelector((state) => state.Incident.newYork_Time_Zone);


    const { updateCount, get_IncidentTab_Count, get_Incident_Count, setIncidentRmsCfs, exceptionalClearID, GetDataExceptionalClearanceID, setChangesStatus, changesStatus, setReportedDtTmInc, GetDataTimeZone, datezone, } = useContext(AgencyContext);
    // const [datezone ,setDatezone] = useState()
    const [reportedDate, setReportedDate] = useState(new Date(datezone));
    const [occuredFromDate, setOccuredFromDate] = useState();
    const [occuredToDate, setOccuredToDate] = useState();
    const [onSelectLocation, setOnSelectLocation] = useState(false);
    const [loder, setLoder] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [addVerifySingleData, setAddVerifySingleData] = useState([]);
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID] = useState('');
    const [incidentID, setIncidentID] = useState();
    const [locationStatus, setLocationStatus] = useState(false);
    const [ucrModelStatus, setUcrModelStatus] = useState(false);
    const [stateReportID, setStateReportID] = useState('');
    const [stateReportCount, setStateReportCount] = useState(1)
    //DropDown Value
    const [rmsCfsID, setRmsCfsID] = useState([]);
    const [editval, setEditval] = useState([]);
    const [clsDrpCode, setClsDrpCode] = useState();
    const [exClsDateCode, setExClsDateCode] = useState();
    const [updateStatus, setUpdateStatus] = useState(0);
    const [effectiveScreenPermission, setEffectiveScreenPermission] = useState([]);
    const [openPage, setOpenPage] = useState('');
    const [incNumberExistStatus, setIncNumberExistStatus] = useState(false);
    const [incNumberGenrateStatus, setIncNumberGenrateStatus] = useState(false);
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);
    const [offenseIdDrp, setOffenseIdDrp] = useState([]);
    // ucr reportdata
    const [UCR10FamVioReportData, setUCR10FamilyViolenceReport] = useState();
    const [hateCrimeReportData, setHateCrimeReport] = useState();
    const [ucr7SexAsaultReportData, setUCR7SexAsaultReportData] = useState();
    //nibrs
    const [checkValidOffender, setcheckValidOffender] = useState([]);

    const [value, setValue] = useState({
        'IncidentID': '', 'ReportedDate': getShowingMonthDateYear(datezone), 'OccurredFrom': '',
        'OccurredTo': '', 'BIBRSDate': getShowingMonthDateYear(datezone), 'FinishedDate': '',
        'DispositionDate': '', 'NIBRSclearancedate': '', 'IncidentNumber': incNumberGenrateStatus ? "Auto Generated" : null, 'CrimeLocation': '',
        'DispositionComments': '', 'AgencyName': '', 'RMSCFSCodeID': '', 'NIBRSClearanceID': '',
        'RMSDispositionId': '', 'ReceiveSourceID': '', 'CADCFSCodeID': '', 'CADDispositionId': '',
        'AgencyID': '', 'IsVerify': true, 'crimelocationid': 0, 'FBIID': '', 'IsIncidentCode': true,
        'DispatchedDate': '', 'ArrivedDate': '', 'DirectionPrefix': '', 'Street': '', 'DirectionSufix': '',
        'TypeSufix': '', 'City': '', 'State': '', 'ZipCode': '', 'PremiseNo': '', 'ApartmentNo': '',
        'CommonPlace': '', 'ApartmentType': '', 'Street_Parse': '', 'PremiseNo_Parse': '', 'DirectionPrefix_Parse': '',
        'TypeSuffix_Parse': '', 'DirectionSuffix_Parse': '', 'ZipCodeID': '', 'CityID': '', 'IsUsLocation': '',
        'CountryID': '', 'Country': '', 'point_of_interest': '', 'neighborhood': '', 'subpremise': '',
        'premise': '', 'CreatedByUserFK': '', 'ModifiedByUserFK': '', 'OffenseType': '', 'OffenseTypeID': '', 'Address': '', 'CADIncidentNumber': '', 'MasterIncidentNumber': '',
    })

    const [errors, setErrors] = useState({
        'OccuredError': '', 'CrimeLocationError': '', 'ExceptionalClearaceError': '', 'IsVerify': '',
        'NIBRSclearancedateError': '', 'DispositionDateError': '', 'IncNumberError': '',
        //Not in use
        'RmsCfsCodeError': '', 'FBIIDError': '',
    })

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    var IncID = query?.get("IncId");
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));

    useEffect(() => {
        if (!localStoreData.AgencyID || !localStoreData.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setValue({ ...value, 'AgencyID': localStoreData?.AgencyID, 'CreatedByUserFK': localStoreData?.PINID });
            setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
            setLoder(true);
            GetDataTimeZone(localStoreData?.AgencyID);
        }
    }, [localStoreData]);

   

    useEffect(() => {
        const defaultDate = datezone ? new Date(datezone) : null;
        setReportedDate(defaultDate);
        setValue({ ...value, 'BIBRSDate': getShowingMonthDateYear(datezone), });
        if (IncID) { setIncidentID(IncID); GetEditData(IncID); getNameOfferndersData(IncID); get_Incident_Count(IncID); get_IncidentTab_Count(IncID); }
        else {
            setIncidentID('');
            // setReportedDate(new Date());
            setOccuredFromDate(null); setClsDrpCode('')
        }
    }, [IncID, datezone]);

    useEffect(() => {
        if (loginAgencyID) {
            if (effectiveScreenPermission?.length === 0) { getScreenPermision(loginAgencyID, loginPinID); }
            if (exceptionalClearID.length === 0) { GetDataExceptionalClearanceID(loginAgencyID); }
            // if (cadDispositionDrpData?.length === 0) { dispatch(get_Incident_Drp_Data(loginAgencyID)) }
            dispatch(get_Incident_Drp_Data(loginAgencyID))
            OffenseIdDrpDwnVal(loginAgencyID);
        }
    }, [loginAgencyID])

    const getRmsCfsCodeID = (FBIID) => {
        const val = { 'FBIID': FBIID, 'AgencyID': null, }
        fetchPostData('ChargeCodes/GetDataDropDown_ChargeCodes', val).then((data) => {
            if (data) {
                setRmsCfsID(Comman_changeArrayFormat(data, 'ChargeCodeID', 'Description'))
            } else {
                setRmsCfsID([]);
            }
        })
    }


    const GetEditData = (incidentID) => {
        const val = { IncidentID: incidentID }
        fetchPostData('Incident/GetSingleData_Incident', val).then((res) => {
            if (res?.length > 0) {
                setEditval(res); setLoder(true);
                setReportedDtTmInc(getShowingDateText(res[0]?.ReportedDate))
            }
            else { setEditval([]); setLoder(true) }
        })
    }

    const getNameOfferndersData = (incidentID) => {
        const val = { IncidentID: incidentID }
        fetchPostData('OffenderAssault/GetData_OffenderByIncident', val).then((res) => {
            if (res?.length > 0) {
                // console.log(res)
                setcheckValidOffender(res)
            } else {
                setcheckValidOffender([]);
            }
        })
    }

    const OffenseIdDrpDwnVal = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('OffenseType/GetDataDropDown_OffenseType', val).then((data) => {
            if (data) {
                setOffenseIdDrp(threeColArray(data, 'OffenseTypeID', 'Description', 'OffenseTypeCode'))
                // setOffenseIdDrp(Comman_changeArrayFormat(data, 'OffenseTypeID', 'Description'))
            } else {
                setOffenseIdDrp([]);
            }
        })
    }

    const getScreenPermision = (LoginAgencyID, PinID) => {
        ScreenPermision("I034", LoginAgencyID, PinID).then(res => {
            if (res) {
                setEffectiveScreenPermission(res)
            } else {
                setEffectiveScreenPermission([])
            }
        });
    }

    useEffect(() => {
        if (incidentID && (IncSta === true || IncSta === 'true')) {
            // setOnSelectLocation(false);
            setValue({
                ...value,
                //drpdown
                'IncidentID': editval[0]?.IncidentID,
                'AgencyID': editval[0]?.AgencyID,
                'CADDispositionId': editval[0]?.CADDispositionId,
                'CADCFSCodeID': editval[0]?.CADCFSCodeID,
                'RMSCFSCodeID': editval[0]?.RMSCFSCodeID,
                'RMSDispositionId': editval[0]?.RMSDispositionId,
                'NIBRSClearanceID': editval[0]?.NIBRSClearanceID,
                'ReceiveSourceID': editval[0]?.ReceiveSourceID,
                'crimelocationid': editval[0]?.crimelocationid,
                'FBIID': editval[0]?.FBIID,
                //date fields
                'IncidentNumber': editval[0]?.IncidentNumber,
                'CADIncidentNumber': editval[0]?.CADIncidentNumber,
                'MasterIncidentNumber': editval[0]?.MasterIncidentNumber,
                'ReportedDate': editval[0]?.ReportedDate ? getShowingDateText(editval[0]?.ReportedDate) : '',
                'OccurredFrom': editval[0]?.OccurredFrom ? getShowingDateText(editval[0]?.OccurredFrom) : '',
                'OccurredTo': editval[0]?.OccurredTo ? getShowingDateText(editval[0]?.OccurredTo) : '',
                'BIBRSDate': editval[0]?.BIBRSDate ? getShowingDateText(editval[0]?.BIBRSDate) : '',
                'DispositionDate': editval[0]?.DispositionDate ? editval[0]?.DispositionDate : '',
                'NIBRSclearancedate': editval[0]?.NIBRSclearancedate ? getShowingDateText(editval[0]?.NIBRSclearancedate) : '',
                'IsVerify': editval[0]?.IsVerify,
                //text
                'CrimeLocation': editval[0]?.CrimeLocation,
                'DispositionComments': editval[0]?.DispositionComments,
                // location column
                'DirectionPrefix': editval[0]?.DirectionPrefix,
                'Street': editval[0]?.Street,
                'DirectionSufix': editval[0]?.DirectionSufix,
                'TypeSufix': editval[0]?.TypeSufix,
                'City': editval[0]?.City,
                'State': editval[0]?.State,
                'OffenseType': editval[0]?.OffenseType,
                'OffenseTypeID': editval[0]?.OffenseTypeID,
                'ZipCode': editval[0]?.ZipCode,
                'PremiseNo': editval[0]?.PremiseNo,
                'ApartmentNo': editval[0]?.ApartmentNo,
                'CommonPlace': editval[0]?.CommonPlace,
                'ApartmentType': editval[0]?.ApartmentType,
                'Street_Parse': editval[0]?.Street_Parse,
                'PremiseNo_Parse': editval[0]?.PremiseNo_Parse,
                'DirectionPrefix_Parse': editval[0]?.DirectionPrefix_Parse,
                'TypeSuffix_Parse': editval[0]?.TypeSuffix_Parse,
                'DirectionSuffix_Parse': editval[0]?.DirectionSuffix_Parse,
                'ZipCodeID': editval[0]?.ZipCodeID,
                'CityID': editval[0]?.CityID,
                'IsUsLocation': editval[0]?.IsUsLocation,
                'CountryID': editval[0]?.CountryID,
                'Country': editval[0]?.Country,
                'point_of_interest': editval[0]?.point_of_interest,
                'neighborhood': editval[0]?.neighborhood,
                'subpremise': editval[0]?.subpremise,
                'premise': editval[0]?.premise,
                'CreatedByUserFK': loginPinID,
                'ModifiedByUserFK': loginPinID,
                'Address': editval[0]?.Address,
            });
            setOccuredFromDate(editval[0]?.OccurredFrom ? new Date(editval[0]?.OccurredFrom) : null);
            setReportedDate(editval[0]?.ReportedDate ? new Date(editval[0]?.ReportedDate) : '');
            setIncidentReportedDate(editval[0]?.ReportedDate ? new Date(editval[0]?.ReportedDate) : null);
            setClsDrpCode(Get_Exceptional_Code(editval, rmsDispositionDrpData));
            setExClsDateCode(Get_ExClsDate_Code(editval, exceptionalClearID));

            setStateReportID(Get_OffenseType_Code(editval, offenseIdDrp));

            setIncidentRmsCfs(editval[0]?.RMSCFSCodeID);
            getRmsCfsCodeID(editval[0]?.FBIID);
            setReportedDtTmInc(getShowingDateText(editval[0]?.ReportedDate));
            setOnSelectLocation(false);
            // UCR10 FamilyViolence Report Data
            GetUCR10ReportData(editval[0]?.IncidentNumber);
            GetHateCrimeReportData(editval[0]?.IncidentNumber);
            GetUCR7SexAsaultReportData(editval[0]?.IncidentNumber);
            //--------------get_Non_Verify_Add-------------------
            if (!editval[0]?.IsVerify && editval[0]?.crimelocationid) {
                get_Add_Single_Data(editval[0]?.crimelocationid);
                // setOnSelectLocation(true);
                setOnSelectLocation(false);
            }
            // console.warn("Call incidet UpdateMode")
            dispatch({ type: Incident_ReportDate, payload: editval[0]?.ReportedDate ? getShowingDateText(editval[0]?.ReportedDate) : '', });
        } else {
            setValue({
                ...value,
                'IncidentNumber': incNumberGenrateStatus ? "Auto Generated" : null, 'ReportedDate': getShowingMonthDateYear(datezone), 'OccurredFrom': null,
                'OccurredTo': null, 'BIBRSDate': getShowingMonthDateYear(datezone), 'FBIID': '', 'CADCFSCodeID': '',
                'DispositionDate': '', 'NIBRSclearancedate': '', 'CrimeLocation': '', 'DispositionComments': '', 'CADDispositionId': '',
                'RMSCFSCodeID': '', 'NIBRSClearanceID': '', 'RMSDispositionId': '', 'ReceiveSourceID': '', 'OffenseType': '', 'OffenseTypeID': '', 'Address': '', 'CADIncidentNumber': '', 'MasterIncidentNumber': '',
            });
            // true before incidentmaster remport
            setLocationStatus(false);
            setUpdateStatus(updateStatus + 1);
            setIncidentReportedDate(getShowingMonthDateYear(new Date()));
            setOnSelectLocation(false);
        }
    }, [editval, updateCount]);

    useEffect(() => {
        offenseIdDrp?.filter(val => {
            if (val.value === value?.OffenseTypeID) {
                // console.log(val?.id)
                setStateReportID(val?.id)
            }
        });
    }, [value.OffenseTypeID, offenseIdDrp]);

    useEffect(() => {
        if (editval?.length == 0) get_INC_NO_Genrate_Status(localStoreData?.AgencyID);
    }, [editval, localStoreData]);

    useEffect(() => {
        if (editval?.length > 0 && rmsDispositionDrpData?.length > 0) {
            setClsDrpCode(Get_Exceptional_Code(editval, rmsDispositionDrpData));
        }
    }, [rmsDispositionDrpData, editval]);

    const GetUCR7SexAsaultReportData = (IncNum) => {
        const val = { IncidentNumber: IncNum }
        fetchPostData('HateCrimeIncidentReport/UCRReport10', val).then((data) => {
            if (data) {
                setUCR7SexAsaultReportData(data);
            } else {
                setUCR7SexAsaultReportData([])
            }
        })
    }

    const GetUCR10ReportData = (IncNum) => {
        const val = { IncidentNumber: IncNum }
        fetchPostData('HateCrimeIncidentReport/UCRReport10', val).then((data) => {
            if (data) {
                setUCR10FamilyViolenceReport(data)
            } else {
                setUCR10FamilyViolenceReport([])
            }
        })
    }

    const GetHateCrimeReportData = (IncNum) => {
        const val = { IncidentNumber: IncNum }
        fetchPostData('HateCrimeIncidentReport/GetData_HateCrimeIncidentReport', val).then((data) => {
            if (data) {
                setHateCrimeReport(data)
            } else {
                setHateCrimeReport([])
            }
        })
    }

    const check_Validation_Error = (e) => {
        if (clsDrpCode === 'EC') {
            if (exClsDateCode != 'N') {
                const ReportedDateErr = RequiredFieldIncident(value.ReportedDate);
                const CrimeLocationErr = onSelectLocation ? 'Select Location' : Space_NotAllow(value.CrimeLocation);
                const NIBRSClearanceIDErr = RequiredFieldIncident(value.NIBRSClearanceID);
                const NIBRSclearancedateErrorErr = RequiredFieldIncident(value.NIBRSclearancedate);
                const DispositionDateErr = value.RMSDispositionId ? RequiredFieldIncident(value.DispositionDate) : 'true';
                const IncNumberErr = !incNumberGenrateStatus ? RequiredFieldIncident(value?.IncidentNumber) : 'true'
                setErrors(prevValues => {
                    return {
                        ...prevValues,
                        ['OccuredError']: ReportedDateErr || prevValues['OccuredError'],
                        ['CrimeLocationError']: CrimeLocationErr || prevValues['CrimeLocationError'],
                        ['ExceptionalClearaceError']: NIBRSClearanceIDErr || prevValues['ExceptionalClearaceError'],
                        ['NIBRSclearancedateError']: NIBRSclearancedateErrorErr || prevValues['NIBRSclearancedateError'],
                        ['DispositionDateError']: DispositionDateErr || prevValues['DispositionDateError'],
                        ['IncNumberError']: IncNumberErr || prevValues['IncNumberError'],
                    }
                });
            } else {
                const ReportedDateErr = RequiredFieldIncident(value.ReportedDate);
                const NIBRSClearanceIDErr = RequiredFieldIncident(value.NIBRSClearanceID);
                const DispositionDateErr = value.RMSDispositionId ? RequiredFieldIncident(value.DispositionDate) : 'true';
                const CrimeLocationErr = onSelectLocation ? 'Select Location' : Space_NotAllow(value.CrimeLocation);
                const IncNumberErr = !incNumberGenrateStatus ? RequiredFieldIncident(value?.IncidentNumber) : 'true'
                setErrors(prevValues => {
                    return {
                        ...prevValues,
                        ['OccuredError']: ReportedDateErr || prevValues['OccuredError'],
                        ['CrimeLocationError']: CrimeLocationErr || prevValues['CrimeLocationError'],
                        ['ExceptionalClearaceError']: NIBRSClearanceIDErr || prevValues['ExceptionalClearaceError'],
                        ['DispositionDateError']: DispositionDateErr || prevValues['DispositionDateError'],
                        ['IncNumberError']: IncNumberErr || prevValues['IncNumberError'],
                    }
                })
            }
        } else {
            const ReportedDateErr = RequiredFieldIncident(value.ReportedDate);
            const CrimeLocationErr = onSelectLocation ? 'Select Location' : Space_NotAllow(value.CrimeLocation);
            const DispositionDateErr = value.RMSDispositionId ? RequiredFieldIncident(value.DispositionDate) : 'true';
            const IncNumberErr = !incNumberGenrateStatus ? RequiredFieldIncident(value?.IncidentNumber) : 'true'
            setErrors(prevValues => {
                return {
                    ...prevValues,
                    ['OccuredError']: ReportedDateErr || prevValues['OccuredError'],
                    ['CrimeLocationError']: CrimeLocationErr || prevValues['CrimeLocationError'],
                    ['DispositionDateError']: DispositionDateErr || prevValues['DispositionDateError'],
                    ['IncNumberError']: IncNumberErr || prevValues['IncNumberError'],
                }
            })
        }
    }

    // Check All Field Format is True Then Submit 
    const { OccuredError, CrimeLocationError, ExceptionalClearaceError, NIBRSclearancedateError, DispositionDateError, IncNumberError } = errors

    useEffect(() => {
        if (clsDrpCode === 'EC') {
            if (exClsDateCode != 'N') {
                if (OccuredError === 'true' && CrimeLocationError === 'true' && ExceptionalClearaceError === 'true' && NIBRSclearancedateError === 'true' && DispositionDateError === 'true' && IncNumberError === 'true') {
                    if (IncSta === true || IncSta === 'true') {
                        UpdateIncident();
                    } else {
                        AddIncident();
                    }
                }
            } else if (OccuredError === 'true' && CrimeLocationError === 'true' && ExceptionalClearaceError === 'true' && DispositionDateError === 'true' && IncNumberError === 'true') {
                if (IncSta === true || IncSta === 'true') {
                    UpdateIncident();
                } else {
                    AddIncident();
                }
            }
        } else if (OccuredError === 'true' && CrimeLocationError === 'true' && IncNumberError === 'true' && DispositionDateError === 'true') {
            if (IncSta === true || IncSta === 'true') {
                UpdateIncident();
            } else {
                AddIncident();
            }
        }
    }, [OccuredError, CrimeLocationError, , ExceptionalClearaceError, NIBRSclearancedateError, DispositionDateError, IncNumberError])

    const Reset = () => {
        setValue({
            ...value,
            'ReportedDate': '', 'OccurredFrom': '', 'OccurredTo': '', 'FinishedDate': '', 'BIBRSDate': '', 'DispositionDate': '',
            'FBIID': '', 'NIBRSclearancedate': '', 'DispositionComments': '', 'IncidentNumber': '', 'RMSCFSCodeID': '', 'CADIncidentNumber': '', 'MasterIncidentNumber': '',
            'NIBRSClearanceID': '', 'RMSDispositionId': '', 'ReceiveSourceID': '', 'CADCFSCodeID': '', 'OffenseType': '', 'OffenseTypeID': '', 'Address': '',
        });
        setErrors({ ...errors, 'OccuredError': '', 'CrimeLocationError': '', 'ExceptionalClearaceError': '', 'NIBRSclearancedateError': '', });
        setOnSelectLocation(true); setExClsDateCode('')
    }

    const HandleChange = (e) => {
        setStatesChangeStatus(true)
        if (e.target.name === 'IsVerify') {
            if (e.target.checked && addVerifySingleData.length > 0) {
                setModalStatus(false);
                setLocationStatus(true); setAddVerifySingleData([]);
                setValue(pre => { return { ...pre, ['CrimeLocation']: '', [e.target.name]: e.target.checked, } });
            } else {
                setValue(pre => { return { ...pre, [e.target.name]: e.target.checked, } });
                setModalStatus(true);
                setLocationStatus(false);
            }
        } else {
            setChangesStatus(true);
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {
        if (clsDrpCode !== "EC") {
            setValue({
                ...value,
                ['NIBRSClearanceID']: ''
            })
        }
    }, [clsDrpCode]);

    const ChangeDropDown = (e, name) => {
        setStatesChangeStatus(true);
        if (e) {
            if (name === "RMSCFSCodeID") {
                setChangesStatus(true)
                setIncidentRmsCfs(e.value);
                setValue({
                    ...value,
                    [name]: e.value
                })
            } else if (name === "RMSDispositionId") {
                setChangesStatus(true);
                setClsDrpCode(e.id);
                setValue({
                    ...value,
                    [name]: e.value,
                    ['DispositionDate']: getShowingMonthDateYear(datezone),
                    ['NIBRSclearancedate']: '',
                });
                setErrors({
                    ...errors, ['OccuredError']: '', ['ExceptionalClearaceError']: '',
                    // BYAMAN
                    ['NIBRSclearancedateError']: '',
                });
            } else if (name === 'FBIID') {
                setChangesStatus(true)
                getRmsCfsCodeID(e.value)
                setValue({
                    ...value,
                    [name]: e.value,
                    ['RMSCFSCodeID']: '',
                });
                setErrors({ ...errors, ['OccuredError']: '', });
            } else if (name === 'NIBRSClearanceID') {
                setChangesStatus(true)
                setExClsDateCode(e.id);
                if (e.id != 'N') {
                    setValue({
                        ...value,
                        [name]: e.value,
                        ['NIBRSclearancedate']: getShowingMonthDateYear(datezone),
                    });
                    setErrors({ ...errors, ['OccuredError']: '', ['NIBRSclearancedateError']: '', });
                } else {
                    setChangesStatus(true)
                    setValue({
                        ...value,
                        [name]: e.value,
                        ['NIBRSclearancedate']: '',
                    });
                    setErrors({ ...errors, ['OccuredError']: '', ['NIBRSclearancedateError']: '', });
                }
            } else {
                setChangesStatus(true)
                setValue({
                    ...value,
                    [name]: e.value,
                })
            }
        } else if (e === null) {
            setChangesStatus(true)
            if (name === "RMSDispositionId") {
                setValue({ ...value, [name]: null, ['NIBRSClearanceID']: null, ['DispositionDate']: '', ['NIBRSclearancedate']: '', });
                setClsDrpCode(''); setExClsDateCode('');
                setErrors({ ...errors, ['OccuredError']: '', ['ExceptionalClearaceError']: '', ['DispositionDateError']: '', ['NIBRSclearancedateError']: "" });
            } else if (name === 'FBIID') {
                setChangesStatus(true)
                setRmsCfsID([]);
                setValue({ ...value, ['FBIID']: "", ['RMSCFSCodeID']: "", })
            } else if (name === 'NIBRSClearanceID') {

                setChangesStatus(true); setExClsDateCode('');
                setValue({ ...value, [name]: null, ['NIBRSclearancedate']: "", });
                setErrors({ ...errors, ['ExceptionalClearaceError']: "", ['NIBRSclearancedateError']: "" });

            } else {
                setChangesStatus(true)
                setValue({ ...value, [name]: null });
            }
        } else {
            setChangesStatus(true);
            setValue({ ...value, [name]: null });
        }
    }

    const onChangeOffenceType = (e, name) => {
        setChangesStatus(true); setStatesChangeStatus(true);
        // console.log(e)
        if (e) {
            if (name == 'OffenseTypeID') {
                setValue({ ...value, ['OffenseTypeID']: e.value });
                setStateReportID(e.id);
            } else {
                setValue({ ...value, [name]: e.value });
            }
        } else {
            if (name == 'OffenseTypeID') {
                setValue({ ...value, ['OffenseTypeID']: null });
                setStateReportID('');
            } else {
                setValue({ ...value, [name]: null });
            }
        }
    }

    const AddIncident = async () => {
        const incNumberExistStatus = await check_INC_NO_Exist(loginAgencyID, value?.IncidentNumber ? value?.IncidentNumber : "Auto Generated", 0)

        if (incNumberExistStatus) {
            toastifyError("Incident Number Already Exists");
            setErrors({ ...errors, ['OccuredError']: '', ['IncNumberError']: '', ['NIBRSclearancedateError']: '', });
        } else {
            const {
                IncidentID, ReportedDate, OccurredFrom,
                OccurredTo, BIBRSDate, FinishedDate,
                DispositionDate, NIBRSclearancedate, IncidentNumber, CrimeLocation,
                DispositionComments, AgencyName, RMSCFSCodeID, NIBRSClearanceID,
                RMSDispositionId, ReceiveSourceID, CADCFSCodeID, CADDispositionId,
                AgencyID, IsVerify, crimelocationid, FBIID, IsIncidentCode,
                DispatchedDate, ArrivedDate, DirectionPrefix, Street, DirectionSufix,
                TypeSufix, City, State, ZipCode, PremiseNo, ApartmentNo,
                CommonPlace, ApartmentType, Street_Parse, PremiseNo_Parse, DirectionPrefix_Parse,
                TypeSuffix_Parse, DirectionSuffix_Parse, ZipCodeID, CityID, IsUsLocation,
                CountryID, Country, point_of_interest, neighborhood, subpremise, OffenseType, OffenseTypeID, CADIncidentNumber, MasterIncidentNumber,
                premise, CreatedByUserFK, ModifiedByUserFK,
            } = value
            const val = {
                'IncidentID': IncidentID, 'ReportedDate': ReportedDate, 'OccurredFrom': OccurredFrom,
                'OccurredTo': OccurredTo, 'BIBRSDate': BIBRSDate, 'FinishedDate': FinishedDate,
                'DispositionDate': DispositionDate, 'NIBRSclearancedate': NIBRSclearancedate, 'IncidentNumber': IncidentNumber, 'CrimeLocation': CrimeLocation,
                'DispositionComments': DispositionComments, 'AgencyName': AgencyName, 'RMSCFSCodeID': RMSCFSCodeID, 'NIBRSClearanceID': NIBRSClearanceID,
                'RMSDispositionId': RMSDispositionId, 'ReceiveSourceID': ReceiveSourceID, 'CADCFSCodeID': CADCFSCodeID, 'CADDispositionId': CADDispositionId,
                'AgencyID': loginAgencyID, 'IsVerify': IsVerify, 'crimelocationid': crimelocationid, 'FBIID': FBIID, 'IsIncidentCode': IsIncidentCode,
                'DispatchedDate': DispatchedDate, 'ArrivedDate': ArrivedDate, 'DirectionPrefix': DirectionPrefix, 'Street': Street, 'DirectionSufix': DirectionSufix, 'CADIncidentNumber': CADIncidentNumber, 'MasterIncidentNumber': MasterIncidentNumber,
                'TypeSufix': TypeSufix, 'City': City, 'State': State, 'ZipCode': ZipCode, 'PremiseNo': PremiseNo, 'ApartmentNo': ApartmentNo,
                'CommonPlace': CommonPlace, 'ApartmentType': ApartmentType, 'Street_Parse': Street_Parse, 'PremiseNo_Parse': PremiseNo_Parse, 'DirectionPrefix_Parse': DirectionPrefix_Parse,
                'TypeSuffix_Parse': TypeSuffix_Parse, 'DirectionSuffix_Parse': DirectionSuffix_Parse, 'ZipCodeID': ZipCodeID, 'CityID': CityID, 'IsUsLocation': IsUsLocation,
                'CountryID': CountryID, 'Country': Country, 'point_of_interest': point_of_interest, 'neighborhood': neighborhood, 'subpremise': subpremise,
                'premise': premise, 'OffenseType': OffenseType, 'OffenseTypeID': OffenseTypeID, 'CreatedByUserFK': loginPinID, 'ModifiedByUserFK': '',
            }
            AddDeleteUpadate('Incident/IncidentInsert', val).then((res) => {

                if (res.success) {
                    setIncNumberGenrateStatus(true);
                    if (res.IncidentID) {
                        get_IncidentTab_Count(parseInt(res?.IncidentID)); GetEditData(parseInt(res?.IncidentID));
                        setOnSelectLocation(false);
                        setIncidentID(parseInt(res?.IncidentID));
                        setChangesStatus(false);
                        setStatesChangeStatus(false);
                        toastifySuccess(res?.Message);
                    }
                    navigate(`/Inc-Home?IncId=${stringToBase64(res?.IncidentID?.trim())}&IncNo=${res?.IncidentNumber?.trim()}&IncSta=${true}`);
                    setErrors({ ...errors, ['OccuredError']: '', ['IncNumberError']: '', ['NIBRSclearancedateError']: '', });
                } else {
                    toastifyError("Error");
                    setErrors({ ...errors, ['OccuredError']: '', ['IncNumberError']: '', ['NIBRSclearancedateError']: '', });
                }
            })
        }
    }

    const UpdateIncident = () => {
        AddDeleteUpadate('Incident/IncidentUpdate', value).then((res) => {
            const parsedData = JSON.parse(res.data);
            const message = parsedData.Table[0].Message;
            toastifySuccess(message);
            get_IncidentTab_Count(incidentID);
            setChangesStatus(false);
            setStatesChangeStatus(false);
            setOnSelectLocation(false);
            GetEditData(IncID);
            setErrors({ ...errors, ['OccuredError']: '', ['ExceptionalClearaceError']: '', });
        })
    }

    const OnClose = () => {
        if (!changesStatus) {
            setChangesStatus(false); setStatesChangeStatus(false); Reset(); navigate('/incident'); setIncidentID('');
        }
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();
    const startRef4 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
            startRef4.current.setOpen(false);
        }
        // //bunty condition for datepicker f5 refresh
        // if (e.target.id === 'ReportedDate' ? e.Key === 'F5' : '') {
        //     e.preventDefault();
        // } else if (e.keyCode === 9 || e.which === 9) {
        //     startRef.current.setOpen(false);
        //     startRef1.current.setOpen(false);
        //     startRef2.current.setOpen(false);
        //     startRef3.current.setOpen(false);
        //     startRef4.current.setOpen(false);
        // }
    };

    const get_Add_Single_Data = (crimelocationid) => {
        const val = { 'LocationID': crimelocationid, }
        fetchPostData('MasterLocation/GetSingleData_MasterLocation', val).then((res) => {
            if (res.length > 0) {
                setAddVerifySingleData(res)
            } else {
                setAddVerifySingleData([])
            }
        })
    }

    const get_INC_NO_Genrate_Status = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID };
        fetchPostData('Incident/NumberGenerate', val).then((data) => {
            if (data[0]?.Message?.trim()) {
                setIncNumberGenrateStatus(true)
            } else {
                setIncNumberGenrateStatus(false)
            }
        })
    };

    const check_INC_NO_Exist = async (LoginAgencyID, IncidentNumber, IncidentID) => {
        const val = { 'AgencyID': LoginAgencyID, 'IncidentNumber': IncidentNumber, 'IncidentID': IncidentID, };
        const data = await fetchPostData('Incident/IncidentExist', val)
        if (data?.length > 0) {
            if (data[0].Total === 0 || data[0].Total === '0') {
                setIncNumberExistStatus(false);
                return false
            } else {
                toastifyError("Incident No already Exist")
                setIncNumberExistStatus(true);
                return true
            }
        } else {
            setIncNumberExistStatus(true);
            return true
        }
    };

    const reportedTime = new Date(reportedDate);
    let reportDate = reportedTime.getDate();
    let reportMonth = reportedTime.getMonth();
    let reportTime = reportedTime.getTime();
    const date = new Date(occuredFromDate);
    let OccuredDate = date.getDate();
    let OccuredMonth = date.getMonth();

    // for occuredfrom date
    const filterPassed = (time) => {
        const selectedDate = new Date(time);
        if (!occuredFromDate) {
            selectedDate.setDate(reportDate)
            selectedDate.setMonth(reportMonth)
            selectedDate.setFullYear(reportedTime.getFullYear())
            // setOccuredFromDate(selectedDate)
            return reportedTime.getTime() >= selectedDate.getTime();
        } else if (occuredFromDate && OccuredDate !== reportDate && reportMonth !== OccuredMonth) {
            return reportedDate.getTime() >= selectedDate.getTime();
        } else if (occuredFromDate && OccuredDate !== reportDate && reportMonth === OccuredMonth) {
            return reportedDate.getTime() >= selectedDate.getTime();
        } else {
            return reportedDate.getTime() >= selectedDate.getTime();
        }

    };

    // for occuredto date
    const filterPassed2 = (time) => {
        const selectedDate = new Date(time);
        const currentDate = new Date(datezone);
        const dd = formatDate(new Date(date.getTime()))
        if (occuredToDate && new Date(occuredToDate)?.getDate() === OccuredDate && reportDate != OccuredDate) {
            return new Date(dd).getTime() <= selectedDate.getTime();
        } else if (reportDate === OccuredDate) {
            return new Date(dd).getTime() <= selectedDate.getTime() && currentDate.getTime() >= selectedDate.getTime();
        } else {
            return currentDate.getTime() > selectedDate.getTime();
        }
    };

    const handleChange = (date) => {
        const newYorkTime = moment(date).tz('America/New_York');
        const formattedTime = newYorkTime.format('YYYY-MM-DD HH:mm:ss z');
    };

    // api/OffenderAssault/GetData_OffenderByIncident
    // IncidentID

    // Custom Style
    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const NibrsErrorscolourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "rgb(255 202 194)",
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const colourStylesEC = {
        control: (styles) => ({
            ...styles,
            backgroundColor: exClsDateCode === 'N' || exClsDateCode === 'A' || exClsDateCode === 'B' || exClsDateCode === 'C' || exClsDateCode === 'D' || exClsDateCode === 'E' ? "#fce9bf" : "rgb(255 202 194)",
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const checkExceptionNibrsError = (exCode) => {
        if (exCode) {
            if (exCode === 'N' || exCode === 'A' || exCode === 'B' || exCode === 'C' || exCode === 'D' || exCode === 'E') {
                if (checkValidOffender?.length == 0) {
                    return NibrsErrorscolourStyles
                } else {
                    return colourStyles
                }
            } else {
                return NibrsErrorscolourStyles
            }
        } else {
            if (clsDrpCode == 'EC' && value?.RMSDispositionId != '') {
                return colourStyles
            } else {
                return customStylesWithOutColor
            }
        }
    }


    // ---- DS

    const filterPassedTimeZones = (time, occuredFromDate, reportedDate) => {
        const selectedDate = new Date(time);
        const occuredFrom = new Date(occuredFromDate);
        const reported = new Date(reportedDate);
        if (selectedDate >= occuredFrom && selectedDate <= reported) {
            return true;
        }
        return false;
    };





    return (
        loder ?
            <>
                <div className="col-12">
                    <div className="row bb">
                        <div className="col-6">
                            <div className="row mb-1">
                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                    <label htmlFor="" className='new-label'>Incident #{errors.IncNumberError !== 'true' ? (
                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.IncNumberError}</p>
                                    ) : null}</label>
                                </div>
                                <div className="col-7 pl-1 col-md-7 col-lg-6  mt-1 ">
                                    <input type="text" name='IncidentNumber' id='IncidentNumber' maxLength={20}
                                        // className='form-control  py-1 new-input'
                                        className={`form-control  py-1 new-input ${!incNumberGenrateStatus ? 'requiredColor' : ''}`}
                                        readOnly={incNumberGenrateStatus}
                                        onChange={
                                            (e) => {
                                                if (!incNumberGenrateStatus) {
                                                    setValue({
                                                        ...value,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }
                                            }
                                        }
                                        onBlur={(e) => check_INC_NO_Exist(loginAgencyID, e.target?.value, 0)}
                                        value={value.IncidentNumber || !incNumberGenrateStatus ? value.IncidentNumber : 'Auto Generated'}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-4 col-md-4 col-lg-6 mt-2">
                                    <label htmlFor="" className='new-label'>Master Incident #</label>
                                </div>
                                <div className="col-7 col-md-7 col-lg-6  mt-1 ">
                                    {/* <input type="text" className='form-control  py-1 new-input' readOnly /> */}
                                    <input type="text" name='MasterIncidentNumber' id='MasterIncidentNumber' className='form-control  py-1 new-input'
                                        value={value.MasterIncidentNumber}
                                        disabled
                                        readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row bb">
                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                            <label htmlFor="" className='new-label'>CAD Event #</label>
                        </div>
                        <div className="col-9 col-md-9 col-lg-3 mt-1 ">
                            <input type="text" name='CADIncidentNumber' id='CADIncidentNumber' className='form-control  py-1 new-input'
                                value={value.CADIncidentNumber}
                                disabled
                                readOnly />
                        </div>

                        <div className="col-3 col-md-3 col-lg-4 mt-2">
                            <label htmlFor="" className='new-label'>NIBRS Date/Time</label>
                        </div>
                        <div className="col-9 col-md-9 col-lg-3">
                            <DatePicker
                                name='BIBRSDate'
                                id='BIBRSDate'
                                open={false}
                                className={'readonlyColor'}
                                // onChange={(date) => { setBibrsDate(date); setValue({ ...value, ['BIBRSDate']: date ? getShowingMonthDateYear(date) : null }) }}
                                ref={startRef3}
                                onKeyDown={onKeyDown}
                                dateFormat="MM/dd/yyyy HH:mm"
                                selected={value?.BIBRSDate && new Date(value.BIBRSDate)}
                                placeholderText={'Select..'}
                                timeInputLabel
                                isClearable={false}
                                disabled={true}
                                // disabled={incidentID ? true : false}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                showTimeSelect
                                timeIntervals={1}
                                timeCaption="Time"
                                minDate={reportedDate}
                                maxDate={datezone ? new Date(datezone) : null}
                                filterTime={(date) => filterPassedTimeZone(date, datezone)}
                            />
                        </div>
                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                            <label htmlFor="" className='new-label'>Reported Date/Time{errors.OccuredError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.OccuredError}</p>
                            ) : null}</label>
                        </div>
                        <div className="col-9 col-md-9 col-lg-3">
                            {
                                incidentID && (IncSta === true || IncSta === 'true') ?
                                    <DatePicker
                                        ref={startRef}
                                        onKeyDown={onKeyDown}
                                        id="ReportedDate"
                                        name='ReportedDate'
                                        dateFormat="MM/dd/yyyy HH:mm"
                                        // dateFormat="y-MM-dd HH:mm"
                                        // y-MM-dd HH:mm
                                        onChange={(date) => {
                                            setStatesChangeStatus(true); setChangesStatus(true);
                                            setReportedDate(date);
                                            setIncidentReportedDate(date ? getShowingMonthDateYear(date) : null)

                                            setValue({
                                                ...value,
                                                ['ReportedDate']: date ? getShowingMonthDateYear(date) : null,
                                                ['OccurredFrom']: null,
                                                ['OccurredTo']: null,
                                                ['DispositionDate']: null,
                                                ['NIBRSclearancedate']: null
                                            });

                                            setOccuredFromDate(null); setOccuredToDate(null);
                                        }}
                                        maxDate={datezone ? new Date(datezone) : null}
                                        filterTime={(date) => filterPassedTimeZone(date, datezone)}
                                        selected={reportedDate}
                                        className='requiredColor'
                                        timeInputLabel
                                        showTimeSelect
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        showDisabledMonthNavigation
                                        autoComplete='off'
                                        // locale="en_GB"
                                        timeFormat="HH:mm "
                                        is24Hour
                                    />
                                    :
                                    <DatePicker
                                        ref={startRef}
                                        onKeyDown={onKeyDown}
                                        id="ReportedDate"
                                        name='ReportedDate'
                                        dateFormat="MM/dd/yyyy HH:mm"
                                        onChange={(date) => {
                                            setStatesChangeStatus(true); setChangesStatus(true);
                                            setReportedDate(date);
                                            setIncidentReportedDate(date ? getShowingMonthDateYear(date) : null)
                                            setValue({ ...value, ['ReportedDate']: date ? getShowingMonthDateYear(date) : null, ['BIBRSDate']: date ? getShowingMonthDateYear(date) : null, ['OccurredFrom']: null, ['OccurredTo']: null, ['DispositionDate']: null, ['NIBRSclearancedate']: null });
                                            setOccuredFromDate(null); setOccuredToDate(null);
                                        }}
                                        maxDate={datezone ? new Date(datezone) : null}
                                        filterTime={(date) => filterPassedTimeZone(date, datezone)}
                                        selected={reportedDate}
                                        className='requiredColor'
                                        timeInputLabel
                                        showTimeSelect
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        showDisabledMonthNavigation
                                        autoComplete='off'
                                        // locale="en_GB"
                                        timeFormat="HH:mm "
                                        is24Hour
                                    />
                            }
                        </div>
                        <div className="col-3 col-md-3 col-lg-4 mt-2 pt-1">
                            <span data-toggle="modal" onClick={() => { setOpenPage('Incident Receive Source') }} data-target="#ListModel" className='new-link px-0'>
                                How Reported
                            </span>
                        </div>
                        <div className="col-9 col-md-9 col-lg-3 mt-2">
                            <Select
                                name='ReceiveSourceID'
                                value={receiveSourceDrpData?.filter((obj) => obj.value === value?.ReceiveSourceID)}
                                isClearable
                                options={receiveSourceDrpData}
                                menuPlacement='bottom'
                                onChange={(e) => ChangeDropDown(e, 'ReceiveSourceID')}
                                placeholder="Select..."
                                styles={customStylesWithOutColor}
                            />
                        </div>
                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                            <label htmlFor="" className='new-label px-0'>Occurred From Date/Time</label>
                        </div>
                        <div className="col-9 col-md-9 col-lg-3 ">
                            <DatePicker
                                ref={startRef1}
                                onKeyDown={(e) => {
                                    if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ' || e.key === 'F5')) {
                                        e.preventDefault();
                                    } else {
                                        onKeyDown(e);
                                    }
                                }}
                                name='OccurredFrom'
                                id='OccurredFrom'
                                // onFocus={() => { if (!occuredFromDate) setOccuredFromDate(new Date()) }}
                                onChange={(date, e) => {
                                    if (date) {
                                        setStatesChangeStatus(true); setChangesStatus(true);
                                        if (date != reportedTime && date > reportedTime) {
                                            date?.setDate(reportDate)
                                            date?.setMonth(reportMonth)
                                            date?.setFullYear(reportedTime.getFullYear())
                                            date?.setTime(reportTime)
                                            setValue({ ...value, ['OccurredFrom']: date ? getShowingMonthDateYear(date) : null, ['OccurredTo']: null });
                                            setOccuredFromDate(date);
                                            setOccuredToDate(null);
                                        } else {
                                            setValue({ ...value, ['OccurredFrom']: date ? getShowingMonthDateYear(date) : null, ['OccurredTo']: null });
                                            setOccuredFromDate(date);
                                            setOccuredToDate(null);
                                        }
                                    } else {
                                        setStatesChangeStatus(true); setChangesStatus(true);
                                        setOccuredFromDate(null); setOccuredToDate(null);
                                        setValue({ ...value, ['OccurredFrom']: null, ['OccurredTo']: null });
                                    }
                                }}
                                isClearable
                                dateFormat="MM/dd/yyyy HH:mm"
                                placeholderText='Select...'
                                selected={occuredFromDate}
                                maxDate={new Date(reportedDate)}
                                filterTime={(date) => filterPassedTimeZone(date, datezone)}
                                timeInputLabel
                                showTimeSelect
                                timeIntervals={1}
                                timeCaption="Time"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                showDisabledMonthNavigation
                                autoComplete='off'
                                // locale="en_GB"
                                timeFormat="HH:mm "
                                is24Hour={true}
                            />
                        </div>
                        <div className="col-3 col-md-3 col-lg-4 mt-2 pt-1">
                            <label htmlFor="" className='new-label'>Occurred to Date/Time</label>
                        </div>
                        <div className="col-7  col-md-7 col-lg-3 ">
                            <DatePicker
                                ref={startRef2}
                                onKeyDown={(e) => {
                                    if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ' || e.key === 'F5')) {
                                        e.preventDefault();
                                    } else {
                                        onKeyDown(e);
                                    }
                                }
                                }
                                id='OccurredTo'
                                name='OccurredTo'
                                onChange={(date) => {
                                    if (date) {
                                        setStatesChangeStatus(true); setChangesStatus(true);

                                        if (date >= new Date()) {
                                            setValue({ ...value, ['OccurredTo']: new Date() ? getShowingMonthDateYear(new Date()) : null })
                                            setOccuredToDate(new Date());
                                        } else if (date <= occuredFromDate) {
                                            setValue({ ...value, ['OccurredTo']: occuredFromDate ? getShowingMonthDateYear(occuredFromDate) : null })
                                            setOccuredToDate(occuredFromDate);
                                        } else {
                                            setValue({ ...value, ['OccurredTo']: date ? getShowingMonthDateYear(date) : null })
                                            setOccuredToDate(date);
                                        }
                                    } else {
                                        setStatesChangeStatus(true); setChangesStatus(true);
                                        setValue({ ...value, ['OccurredTo']: null })
                                        setOccuredToDate(null);
                                    }
                                }}
                                dateFormat="MM/dd/yyyy HH:mm"
                                disabled={!value?.OccurredFrom && true}
                                className={!value?.OccurredFrom && 'readonlyColor'}
                                timeInputLabel
                                isClearable
                                selected={value?.OccurredTo ? new Date(value.OccurredTo) : null}
                                placeholderText={value?.OccurredTo ? value.OccurredTo : 'Select...'}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                minDate={new Date(value?.OccurredFrom)}
                                maxDate={new Date(reportedDate)}
                                showDisabledMonthNavigation
                                showTimeSelect
                                timeIntervals={1}
                                timeCaption="Time"
                                autoComplete='Off'
                                timeFormat="HH:mm "
                                is24Hour
                                filterTime={(date) => filterPassedTimeZones(date, occuredFromDate, reportedDate)}  // Custom time filter
                            />
                        </div>

                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                            <label htmlFor="" className='new-label'>Crime Location{errors.CrimeLocationError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.CrimeLocationError}</p>
                            ) : null}</label>
                        </div>
                        <div className="col-6  col-md-6 col-lg-8 text-field mt-2 mb-1">
                            <Location
                                {...{ value, setValue, locationStatus, setLocationStatus, updateStatus, setOnSelectLocation, setChangesStatus, setStatesChangeStatus }}
                                col='CrimeLocation'
                                locationID='crimelocationid'
                                check={true}
                                verify={value.IsVerify}
                                style={{ resize: 'both' }}
                            />
                        </div>
                        <div className="col-2 col-md-2 col-lg-2 mt-2 mb-2 d-flex">
                            <div className="form-check custom-control custom-checkbox">
                                <input className="custom-control-input" data-toggle="modal" data-target="#VerifyModal" type="checkbox" name='IsVerify'
                                    checked={(value?.IsVerify || !value?.crimelocationid)}
                                    value={value?.IsVerify} onChange={HandleChange} id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                <label className="custom-control-label " htmlFor="flexCheckDefault" style={{ fontSize: '14px' }}>
                                    Verify
                                </label>
                            </div>
                            {
                                !value?.IsVerify && addVerifySingleData.length > 0 ?
                                    <i className="fa fa-edit pl-3 mt-1 pt-1 " onKeyDown={''} onClick={() => { if (value.crimelocationid) { get_Add_Single_Data(value.crimelocationid); setModalStatus(true); } }} data-toggle="modal" data-target="#VerifyModal" style={{ cursor: 'pointer', backgroundColor: '' }} > Edit </i>
                                    :
                                    <>
                                    </>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 col-md-3 col-lg-2 mt-3 ">
                            <span className='new-label'>
                                Offense Type
                            </span>
                        </div>
                        <div className="col-9 col-md-9 col-lg-8 mt-1 ">
                            <Select
                                name='OffenseId'
                                styles={customStylesWithOutColor}
                                value={offenseIdDrp?.filter((obj) => obj.value === value?.OffenseTypeID)}
                                isClearable
                                options={offenseIdDrp}
                                onChange={(e) => onChangeOffenceType(e, 'OffenseTypeID')}
                                placeholder="Select..."
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-1 ">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="row">
                                <div className="col-3 col-md-3 col-lg-4 mt-2 ">
                                    <span className='new-label'>
                                        CAD CFS Code
                                    </span>
                                </div>
                                <div className="col-9 col-md-9 col-lg-7  mt-1">
                                    <Select
                                        name='CADCFSCodeID'
                                        value={cadCfsCodeDrpData?.filter((obj) => obj.value === value?.CADCFSCodeID)}
                                        isClearable
                                        menuPlacement='bottom'
                                        options={cadCfsCodeDrpData}
                                        onChange={(e) => ChangeDropDown(e, 'CADCFSCodeID')}
                                        placeholder="Select..."
                                        styles={customStylesWithOutColor}
                                    />
                                </div>
                                <div className="col-3 col-md-3 col-lg-4 mt-2 ">
                                    <span data-toggle="modal" data-target="#ListModel" onClick={() => { setOpenPage('Incident Disposition') }} className='new-link '>
                                        RMS Disposition
                                    </span>
                                </div>
                                <div className="col-9  col-md-9 col-lg-7 mt-1">
                                    <Select
                                        name='RMSDispositionId'
                                        value={rmsDispositionDrpData?.filter((obj) => obj.value === value?.RMSDispositionId)}
                                        isClearable
                                        options={rmsDispositionDrpData}
                                        onChange={(e) => ChangeDropDown(e, 'RMSDispositionId')}
                                        placeholder="Select..."
                                        styles={customStylesWithOutColor}
                                    />
                                </div>
                                <div className="col-3 col-md-3 col-lg-4 mt-3 ">
                                    <span data-toggle="modal" data-target="#ListModel" className='new-link ' onClick={() => { setOpenPage('Cleared Exceptionally') }}>
                                        Exceptional Clearance
                                        {
                                            process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ?
                                                exClsDateCode === 'N' || exClsDateCode === 'A' || exClsDateCode === 'B' || exClsDateCode === 'C' || exClsDateCode === 'D' || exClsDateCode === 'E'
                                                    ?
                                                    checkValidOffender?.length > 0 ? <></> : ErrorTooltip(checkValidOffenderError)
                                                    :
                                                    exClsDateCode && <span className='hovertext' style={{ marginLeft: '15px' }} data-hover="Valid codes for clearance code are A,B,C,D,E,N" ><i className='fa fa-exclamation-circle'></i></span>
                                                :
                                                <></>
                                        }

                                        {/* {
                                            exClsDateCode === 'N' || exClsDateCode === 'A' || exClsDateCode === 'B' || exClsDateCode === 'C' || exClsDateCode === 'D' || exClsDateCode === 'E'
                                                ?
                                                <></>
                                                :
                                                exClsDateCode && <span className='hovertext' style={{ marginLeft: '15px' }} data-hover="Valid codes for clearance code are A,B,C,D,E,N" ><i className='fa fa-exclamation-circle'></i></span>
                                        } */}

                                        {errors.ExceptionalClearaceError !== 'true' ? (
                                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.ExceptionalClearaceError}</p>
                                        ) : null}
                                    </span>
                                </div>
                                <div className="col-9 col-md-9 col-lg-7 mt-1 ">
                                    <Select
                                        name='NIBRSClearanceID'
                                        styles={
                                            process.env.REACT_APP_NIBRS_STATE === 'Rajasthan'
                                                ?
                                                clsDrpCode == 'EC' && value?.RMSDispositionId != ''
                                                    ?
                                                    checkExceptionNibrsError(exClsDateCode)
                                                    :
                                                    customStylesWithOutColor
                                                :
                                                clsDrpCode == 'EC' && value?.RMSDispositionId != '' ? colourStylesEC : customStylesWithOutColor
                                        }

                                        // styles={(clsDrpCode == 'EC' && value?.RMSDispositionId != '') ? colourStylesEC : customStylesWithOutColor}
                                        value={exceptionalClearID?.filter((obj) => obj.value === value?.NIBRSClearanceID)}
                                        isClearable
                                        // readOnly={(clsDrpCode == 'EC' && value?.RMSDispositionId != '') ? false : true}
                                        options={exceptionalClearID}
                                        onChange={(e) => ChangeDropDown(e, 'NIBRSClearanceID')}
                                        placeholder="Select..."
                                        isDisabled={(clsDrpCode == 'EC' && value?.RMSDispositionId != '') ? false : true}

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="row">
                                <div className="col-3 col-md-3 col-lg-5 mt-2 ">
                                    {/* <span data-toggle="modal" data-target="#ListModel" className='new-link ' onClick={() => { setOpenPage('Incident Disposition') }}>
                                        CAD Disposition
                                    </span> */}
                                    <span className='new-label'>
                                        CAD Disposition
                                    </span>
                                </div>
                                <div className="col-9  col-md-9 col-lg-7 mt-1">
                                    <Select
                                        name='CADDispositionId'
                                        value={cadDispositionDrpData?.filter((obj) => obj.value === value?.CADDispositionId)}
                                        isClearable
                                        menuPlacement='bottom'
                                        options={cadDispositionDrpData}
                                        onChange={(e) => ChangeDropDown(e, 'CADDispositionId')}
                                        placeholder="Select..."
                                        styles={customStylesWithOutColor}
                                    />
                                </div>
                                <div className="col-3 col-md-3 col-lg-5 mt-2 pt-1">
                                    <label htmlFor="" className='new-label'>Disposition Date/Time{errors.DispositionDateError !== 'true' ? (
                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.DispositionDateError}</p>
                                    ) : null}</label>
                                </div>
                                <div className="col-9  col-md-9 col-lg-7 ">
                                    <DatePicker
                                        name='DispositionDate'
                                        id='DispositionDate'
                                        onChange={(date) => {
                                            if (date) {
                                                if (date >= new Date()) {
                                                    setChangesStatus(true); setStatesChangeStatus(true);
                                                    setValue({ ...value, ['DispositionDate']: new Date() ? getShowingDateText(new Date()) : null })
                                                } else if (date <= reportedDate) {
                                                    setChangesStatus(true); setStatesChangeStatus(true);
                                                    setValue({ ...value, ['DispositionDate']: reportedDate ? getShowingDateText(reportedDate) : null })
                                                } else {
                                                    setChangesStatus(true); setStatesChangeStatus(true);
                                                    setValue({ ...value, ['DispositionDate']: date ? getShowingDateText(date) : null })
                                                }
                                            } else {
                                                setChangesStatus(true); setStatesChangeStatus(true);
                                                setValue({ ...value, ['DispositionDate']: null })
                                            }
                                        }}
                                        selected={value?.DispositionDate && new Date(value?.DispositionDate)}
                                        ref={startRef}
                                        onKeyDown={(e) => {
                                            if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ' || e.key === 'F5')) {
                                                e.preventDefault();
                                            } else {
                                                onKeyDown(e);
                                            }
                                        }}
                                        placeholderText={value?.DispositionDate ? value?.DispositionDate : 'Select...'}
                                        dateFormat="MM/dd/yyyy HH:mm"
                                        timeFormat="HH:mm "
                                        is24Hour
                                        timeInputLabel
                                        isClearable={value?.DispositionDate ? true : false}
                                        maxDate={new Date()}
                                        // peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete='Off'
                                        dropdownMode="select"
                                        showTimeSelect
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        disabled={value?.RMSDispositionId ? false : true}
                                        minDate={reportedDate}
                                        className={value?.RMSDispositionId ? 'requiredColor' : 'readonlyColor'}
                                        filterTime={(date) => filterPassedTimeZone(date, datezone)}
                                    />
                                </div>
                                <div className="col-5 col-md-5 col-lg-5 mt-2 pt-1">
                                    <label htmlFor="" className='new-label '>Exceptional Clearance Date/Time   {errors.NIBRSclearancedateError !== 'true' ? (
                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.NIBRSclearancedateError}</p>
                                    ) : null}</label>
                                </div>
                                <div className='col-7  col-md-7 col-lg-7 '>
                                    <DatePicker
                                        name='NIBRSclearancedate'
                                        id='NIBRSclearancedate'
                                        className={value.NIBRSClearanceID && exClsDateCode !== 'N' ? 'requiredColor' : 'readonlyColor'}
                                        onChange={(date) => {
                                            if (date) {
                                                setStatesChangeStatus(true); setChangesStatus(true);
                                                if (date >= new Date()) {
                                                    setValue({ ...value, ['NIBRSclearancedate']: new Date() ? getShowingDateText(new Date()) : null })
                                                } else if (date <= reportedDate) {
                                                    setValue({ ...value, ['NIBRSclearancedate']: reportedDate ? getShowingDateText(reportedDate) : null })
                                                } else {
                                                    setValue({ ...value, ['NIBRSclearancedate']: date ? getShowingDateText(date) : null })
                                                }
                                            } else {
                                                setStatesChangeStatus(true); setChangesStatus(true);
                                                setValue({ ...value, ['NIBRSclearancedate']: null })
                                            }

                                        }}
                                        ref={startRef4}
                                        onKeyDown={(e) => {
                                            if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ' || e.key === 'F5')) {
                                                e.preventDefault();
                                            } else {
                                                onKeyDown(e);
                                            }
                                        }
                                        }
                                        selected={value?.NIBRSclearancedate && new Date(value?.NIBRSclearancedate)}
                                        placeholderText={value?.NIBRSclearancedate ? value?.NIBRSclearancedate : 'Select...'}
                                        dateFormat="MM/dd/yyyy HH:mm"
                                        timeFormat="HH:mm "
                                        is24Hour
                                        timeInputLabel
                                        isClearable={value?.NIBRSclearancedate ? true : false}
                                        autoComplete='Off'
                                        // peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        showTimeSelect
                                        timeIntervals={1}
                                        disabled={value?.NIBRSClearanceID && exClsDateCode !== 'N' ? false : true}
                                        minDate={reportedDate}
                                        maxDate={new Date()}
                                        filterTime={(date) => filterPassedTimeZone(date, datezone)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1">
                            <label htmlFor="" className='new-label '> Disposition Comments</label>
                        </div>
                        <div className="col-10  col-md-10 col-lg-10 mt-1 text-field" >
                            <textarea name='DispositionComments' value={value.DispositionComments} onChange={HandleChange} id="" cols="30" rows='2'
                                className="form-control " style={{ resize: 'none' }}></textarea>
                        </div>
                    </div>
                </div>
                {/* <div className="col-12 field-button" style={{ position: 'absolute', bottom: '40px', textAlign: 'right' }}> */}
                <div className="col-12 text-right" >
                    {
                        (IncSta === true || IncSta === 'true') ?
                            effectiveScreenPermission ?
                                effectiveScreenPermission[0]?.Changeok ?
                                    <>
                                        {
                                            stateReportID == "SA" || stateReportID == "FV" || stateReportID == "HC" ?
                                                <>
                                                    <button
                                                        type="button" className="btn btn-sm btn-success mr-4"
                                                        onClick={() => { setUcrModelStatus(true); setStateReportCount(stateReportCount + 1) }}
                                                        data-toggle={"modal"}
                                                        data-target={
                                                            stateReportID == "SA" ? "#SexualAsaultReport"
                                                                :
                                                                stateReportID == "FV" ? "#FamilyViolenceReport"
                                                                    :
                                                                    stateReportID == "HC" ? "#HateCrimeReport"
                                                                        :
                                                                        ''
                                                        }
                                                    >
                                                        State Report
                                                    </button>
                                                </>
                                                :
                                                <></>
                                        }
                                        <button type="button" disabled={!statesChangeStatus} className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} > Update </button>
                                    </>
                                    :
                                    <>
                                    </>
                                :
                                <>
                                    {
                                        stateReportID == "SA" || stateReportID == "FV" || stateReportID == "HC" ?
                                            <>
                                                <button
                                                    type="button" className="btn btn-sm btn-success mr-4"
                                                    onClick={() => { setUcrModelStatus(true); setStateReportCount(stateReportCount + 1) }}
                                                    data-toggle={"modal"}
                                                    data-target={
                                                        stateReportID == "SA" ? "#SexualAsaultReport"
                                                            :
                                                            stateReportID == "FV" ? "#FamilyViolenceReport"
                                                                :
                                                                stateReportID == "HC" ? "#HateCrimeReport"
                                                                    :
                                                                    ''
                                                    }
                                                >
                                                    State Report
                                                </button>
                                            </>
                                            :
                                            <></>
                                    }
                                    <button type="button" disabled={!statesChangeStatus} className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} > Update </button>
                                </>
                            :
                            effectiveScreenPermission ?
                                effectiveScreenPermission[0]?.AddOK ?
                                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} > Save </button>
                                    :
                                    <>
                                    </>
                                :
                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} > Save </button>
                    }
                    <button type="button" className="btn btn-sm btn-success mr-4" onClick={() => { OnClose() }} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>Close</button>
                </div>
                <VerifyLocation {...{ loginAgencyID, modalStatus, setModalStatus, value, setValue, addVerifySingleData, get_Add_Single_Data, setOnSelectLocation, setStatesChangeStatus }} />
                <ChangesModal func={check_Validation_Error} />
                <ListModal {...{ openPage, setOpenPage }} />

                <UCR7Report setStateReportID={setStateReportID} stateReportCount={stateReportCount} ucrIncidentNumber={value?.IncidentNumber} ucrModelStatus={ucrModelStatus} setUcrModelStatus={setUcrModelStatus} reportData={ucr7SexAsaultReportData} />

                <UCR10Report setStateReportID={setStateReportID} stateReportCount={stateReportCount} ucrIncidentNumber={value?.IncidentNumber} ucrModelStatus={ucrModelStatus} setUcrModelStatus={setUcrModelStatus} reportData={UCR10FamVioReportData} />

                <HateCrimeIncReport setStateReportID={setStateReportID} stateReportCount={stateReportCount} ucrIncidentNumber={value?.IncidentNumber} ucrModelStatus={ucrModelStatus} setUcrModelStatus={setUcrModelStatus} reportData={hateCrimeReportData} />
                <IdentifyFieldColor />

            </>
            :
            <Loader />
    )
}

export default IncidentHome

const Get_Exceptional_Code = (data, dropDownData) => {
    const result = data?.map((sponsor) => (sponsor.RMSDispositionId));
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor?.value === result[0]) {
            return { value: result[0], label: sponsor?.label, id: sponsor?.id }
        }
    });
    const val = result2?.filter(function (element) { return element !== undefined; });
    return val ? val[0]?.id : null;
}

const Get_OffenseType_Code = (data, dropDownData) => {
    const result = data?.map((sponsor) => (sponsor.OffenseTypeID))

    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    })

    const val = result2?.filter(function (element) {
        return element !== undefined;
    });
    return val ? val[0]?.id : null;
}

const Get_ExClsDate_Code = (data, dropDownData) => {
    const result = data?.map((sponsor) =>
        (sponsor.NIBRSClearanceID)
    )
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    })
    const val = result2?.filter(function (element) {
        return element !== undefined;
    });
    return val ? val[0]?.id : null;
}

export const changeArrayFormat = (data, type) => {
    if (type === 'zip') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.zipId, label: sponsor.Zipcode })
        )
        return result
    }
    if (type === 'state') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.StateID, label: sponsor.StateName })
        )
        return result
    }
    if (type === 'city') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.CityID, label: sponsor.CityName })
        );
        return result
    }

    if (type === 'RmsCfsID') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.RMSCFSCodeID, label: sponsor.RMSCFSCode })
        )
        return result
    }
    if (type === 'RmsDisposition') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.IncidentDispositionsID, label: sponsor.RMSDispositionCode })
        )
        return result
    }
    if (type === 'ExceptionClearID') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.ClearanceID, label: sponsor.ClearanceCode })
        )
        return result
    }
    if (type === 'ReciveSrcID') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.ReceiveSourceID, label: sponsor.ReceiveSourceCode })
        )
        return result
    }
}

export const changeArrayFormat_WithFilter = (data, type, DropDownValue) => {
    if (DropDownValue) {
        if (type === 'CADCFSCodeID') {
            const result = data?.map((sponsor) =>
                (sponsor.CADCFSCodeID)
            )
            const result2 = DropDownValue?.map((sponsor) => {
                if (sponsor.value === result[0]) {
                    return { value: result[0], label: sponsor.label }
                }
            }
            )
            const val = result2?.filter(function (element) {
                return element !== undefined;
            });
            return val ? val[0] : null
        }
    }
}
